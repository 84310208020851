export default {
  subTitle: 'Payment',
  isConnectedPayment: {
    text: 'This payment is part of another payment',
    viewPaymentLink: 'View payment'
  },

  info: {
    stripeInvoiceId: 'Stripe invoice',
    connectedPayment: 'Associated payment',
    userProductSubscription: 'Paid with membership',
    pointTransaction: 'Point account',
    priceffDynamicPriceUsages: 'Used dynamic prices',
    value: 'Value',
    moneyValue: 'Money value',
    pointValue: 'Point value'
  },

  action: {
    refund: 'Cancel and / or refund'
  },

  connectedPaymentsTableTitle: 'Associated payments',
  reversalRefundTitle: 'This refund was likely processed as a reversal',
  whatDoesThisMean: 'What does this mean?',
  reversalRefundDescription: 'Since the refund took place shortly after the payment was made, the payment has most likely been reversed. This means that the refund will not appear on the customers bank statement. Instead, it will appear as if the transaction never took place.'
}