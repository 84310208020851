export default {
  subTitle: 'Booking',
  resourceBookingTitle: 'Court booking',
  noLightsWillTurnOnWarning: 'Automatic lighting is switched off on this booking',

  infoCards: {
    resource: 'Court',
    time: 'Time'
  },

  payments: 'Payment distribution',
  bookliVideo: 'Video export',

  info: {
    comments: 'Title',
    workshop: 'Connected to event',
    recurringResourceBooking: 'Connected to recurring booking',
    dynamicPrices: 'Used dynamic prices',
    accessCodeAccessTime: 'Door access code',
    internalNotes: 'Internal notes',
    additionalService: 'Additional services'
  },

  attendees: {
    title: 'Participants',
    addAttendee: 'Add user',
    noAttendees: {
      title: 'No participants',
      text: 'No participants have been added to this booking...'
    },
    showUser: 'View user',
    payments: 'Payments',
    showAccessCode: 'View door access code'
  },

  actions: {
    updateBooking: 'Update booking',
    refundPayment: 'Cancel and / or refund',
    cancelBooking: 'Cancel booking'
  },

  cancelBooking: {
    successToast: 'Booking status is changed'
  },

  updateResourceBookingModal: {
    title: 'Update booking',
    invalidTime: 'Time chosen is invalid. If you are trying to make a booking which stretches to the next day, you can create two bookings instead',
    changeResource: 'Change court',
    noResources: 'There are no available courts at this time',
    sendMessageToUser: 'Send notification to booking participants about change',
    changeButton: 'Update booking',
    changeLoadingButton: 'Updating booking...',
    successToast: 'The booking has been updated',
    missingNowResourceToast: {
      title: 'A new court is missing',
      text: 'You have not selected a court'
    }
  },

  addUsersBookingModal: {
    title: 'Add users to this booking',
    addButton: 'Add users',
    addLoadingButton: 'Adding users...'
  },

  deleteAttendeeSuccessToast: 'The user has been removed.',
  addAttendee: 'The user has benn added.'
}