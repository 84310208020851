export default {
  title: 'Create Account',
  description: 'Get access to booking courts',

  createWithApple: 'Create with Apple',
  createWithFacebook: 'Create with Facebook',

  birthdayInfo: 'Your date of birth is used to analyze our users. We do not link your date of birth with your user.',
  genderInfo: 'Your gender is used to analyze our users. We do not link your gender with your user.',
  adressInfo: 'We use your address to approve your payments.',
  emailInfo: 'We use your email to be able to validate your user and with messages about your purchases.',
  phoneNumberInfo: 'We use your phone number to be able to remind you of your bookings and validation of your payments.',

  createAccountButton: 'Create account',
  createAccountLoadingButton: 'Creating...',

  alreadyHaveAAccount: 'Already have an account?',
  alreadyHaveAAccountButton: 'Sign in',

  signUpPrivacyPolicy: "By signing up, you agree to LeDap's",
  privacyPolicy: 'Privacy policy',

  createdAccount: {
    title: 'Your account has been created',
    description: 'You can now log in and make use of the services the platform offers!',
    goToSignIn: 'Go to sign in'
  }

}