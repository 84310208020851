export default {
  title: 'Create reserved time',
  note: {
    label: 'Title',
    placeholder: 'Padel...',
    desription: 'The title is shown in the calendar'
  },
  courts: {
    label: 'Courts',
    selectAll: 'Select all',
    errorSelectCourts: 'You need to select a court',
  },
  attendees: {
    label: 'Attendees'
  },
  internalNotes: {
    label: 'Internal notes',
    placeholder: 'My note...'
  },
  type: {
    label: 'Select a type'
  },
  addAccessCodeToBooking: 'Add access code to booking',
  turnOnLightsAtStart: 'Turn the lights on',
  addCreatorAsAttendee: 'Add yourself as a attendee',
  date: {
    label: 'Date',
    description: 'You are able to select multiple days by choosing the days.'
  },
  startTime: {
    label: 'Start'
  },
  endTime: {
    label: 'End'
  },

  createReservedTimeButton: 'Create reservation',
  createReservedTimeLoadingButton: 'Creating reservation...',
  createdSuccessToast: 'Your reservation is created.',

  failedOnCourts: {
    text: 'There was an error with the fowlling courts: {0}. Probably because an exsting booking',
    title: 'An error happened'
  }
}