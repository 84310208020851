export default {
  title: 'Create recurring booking',
  description: 'You are about to create a recurring booking. Fill the inputs below.',

  resources: {
    label: 'Choose courts'
  },
  attendees: {
    label: 'Attendees'
  },
  lockAttendeesChange: 'Make sure participants can not unsubscribe',

  notNeededDescription: '(The next inputs is not needed)',
  startCreateDate: {
    label: 'Only create bookings AFTER the following date',
    placeholder: 'Date'
  },
  endCreateDate: {
    label: 'Stop create bookings AFTER the following date',
    placeholder: 'Date'
  },
  createAllBookings: 'Create all bookings to end date',
  createCyclesInAdvance: {
    label: 'Weeks witch should be created ahead'
  },
  createResourceBookingRecurringButton: 'Create recurring booking',
  createResourceBookingRecurringLoadingButton: 'Creating recurring booking...',
  repeatBooking: "Recurring Booking",
  disabled: "This recurring booking is disabled",
  start: "Start Time",
  end: "End Time",
  creationStart: "Creation Start Time",
  creationEnd: "Creation End Time",
  connectedToUser: "Connected to User",
  connectedToUsergroup: "Connected to Usergroup",
  participants: "Participants",
  showUserGroups: "Show User Group",
  cancelRecurringBooking: "Cancel this recurring booking",
  sure: "Are you sure",
  sureWannaCancel: "Are you sure you want to cancel this recurring booking?",
  cancelFutureBookings: "Cancel all future bookings",
  updateRecurringBooking: "Update recurring booking information",
  selectCourt: "Select Courts",
  notRequired: "(The next two fields are not required)",
  createAfterDate: "Create bookings starting AFTER the date below",
  date: "Date",
  stopAfterDate: "Stop creating bookings after the date below",
  createToEndDate: "Create all bookings up to the end date",
  weekInFuture: "Weeks to create in the future",
  updateInfo: "Update information",
  actionDone: 'Your action is completed',
  recurringBookingDeleted: "Recurring booking is deleted",
}