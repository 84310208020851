export default {
  subTitle: 'Event',
  isCanceled: 'This event has been canceled',

  infoTab: {
    title: 'Informations',
    infoCards: {
      bookingsCount: 'Registrations',
      paymentsSum: 'Revenue sum'
    },

    info: {
      type: 'Type',
      lastRegistrationDate: 'Latest registration date',
      allowUserProductSubscriptionPayment: 'Can be paid with membership?',
      private: 'Private event',
      privatePassword: 'Password for sign up'
    },

    actions: {
      addAttendee: 'Add participants',
      cancel: 'Cancel event',
      userURL: 'Copy URL to the event for users',
    },

    days: {
      title: 'Days',
      addDay: 'Add day'
    },

    addUsersToWorkshopModal: {
      title: 'Add participants to event',
      addButton: 'Add user',
      addLoadingButton: 'Adding user...',
      successToast: 'The user has been added'
    },

    workshopDayUpdateModal: {
      title: 'Update day',
      text: '(Change of date does not change the date of any. associated court bookings)',
      submitButton: 'Update day',
      successToast: 'The day has been updated'
    },

    createDay: {
      title: 'Create day',
      submitButton: 'Create day',
      successToast: 'The day has been created'
    }
  },

  attendeesTab: {
    title: 'Participants',
    attendeesTable: {
      title: 'Participants',
      user: 'User',
      status: 'Status',
      payment: 'Payment',
      userBookingNote: 'Note'
    },
    waitlistTable: {
      user: 'User',
      createdAt: 'Created at'
    }
  },

  settingsTab: {
    title: 'Settings',

    price: {
      label: 'Price for participation',
      placeholder: 'Price for participation'
    },
    lastRegistrationDate: {
      label: 'Latest registration date',
      placeholder: 'Latest registration date'
    },
    maxRegistrations: {
      label: 'Max participants'
    },
    submitButton: 'Update',
    successToast: 'The event has been updated'
  },

  workshopDayDeleteSuccessToast: 'Day is deleted',
  canceleUserWorkshopBookingSuccessToast: 'The user is removed',

  cancelWorkshop: {
    successToast: 'The event has been canceled',
    sure: {
      text: 'Are you sure you want to delete this event? All registrations will be canceled and refunded. We also send a message to all participants.',
    }
  },
  url: {
    successful: 'Copied!',
    unsuccessful: 'Oops, unable to copy'
  }
}