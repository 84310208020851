export default {

  closeModalText: 'Close',

  exstingSubscription: {
    text: 'Your subscription at',

    membershipName: 'MEMBERSHIP',
    createdAt: 'CREATED AT',
    status: 'STATUS',

    toActive: {
      title: 'Your membership will be activated later',
      text: 'You have registered for a membership, starting date'
    },

    cancelAt: 'Your membership expires on',
    cancelSubscriptionButton: 'Terminate membership',
    reactivateMembership: 'Reactivate membership'
  },

  createSubscription: {
    title: 'Create membership',
    buyText: {
      one: 'You are about to sign up for membership',
      two: 'to',
      four: 'and a set-up fee of',
      five: 'The first payment will be deducted on'
    },
    acceptSubscriptionAgreement: {
      text: 'Accept our terms of membership.',
      readMoreLink: 'Read conditions'
    },
    createdAtCompany: {
      one: 'Your membership will be created at',
      two: 'If you want to activate it somewhere else, close this window and change location.'
    },
    youNeedToAcceptAgreements: 'To proceed, you must accept our terms.',

    done: {
      title: 'Your membership has been created',
      text: 'You are now part of the padel community! We look forward to seeing you in our center.',
      button: 'Back to start'
    },

    paymentFailed: {
      shortTitle: 'Payment failed',
      title: 'Oops - Payment for your membership failed',
      text: 'Something went wrong with the membership payment. The membership will be activated as soon as the invoice is paid. If the invoice is not paid, we will cancel the membership.',
      button: 'Pay invoice',
      buttonLoading: 'Creating invoice'
    },
    activateMembership: 'Create Membership',
    activateMembershipInformation: "By clicking <span class='text-dark'>Create Membership</span>, a membership will be created, but it will not be active until the first invoice has been paid. After this step, you will be directed to the payment page to complete your first invoice."
  },

  cancelProductSubscription: {
    sureModal: { 
      title: 'Are you sure?',
      text: 'You are about to cancel your membership. When your membership ends, the bookings it has been used for will be CANCELLED. Your membership will stop:'
    },
    successToast: 'Your membership has been terminated'
  },

  reactivateProductSubscription: {
    sureModal: {
      title: 'Reactivate membership',
      text: 'You are about to reactivate your membership. The membership will be activated and ready to use directly.',
      activate: 'Activate'
    },
    successToast: 'Your membership has been reactivated'
  }

}