export default {
  info: {
    when: 'WHEN',
    time: 'TIME',
    where: 'WHERE',
    resource: 'COURT',
    status: 'STATUS',
    price: 'PRICE'
  },

  isCreated: 'Your booking is created! 🎾',
  description: "Confirmation email has been sent to your email. You will also receive an SMS, the same day as your booking. We look forward to seeing you and have a great match!",

  actions: {
    addAttendees: 'Add participants',
    addCalendar: 'Add to your private calendar',
    payments: 'Show payment distribution',
    share: 'Share booking with friends',
    view: 'Show booking',
    createNew: 'Create a new booking',
    toHome: 'Go to home'
  },

  downloadAppText: "We have created our own app which makes it extra easy to book with us. Download the app and book your next padel court within a few minutes.",

  bookingToCalendarModal: {
    title: 'Add booking to your calendar',
    close: 'Close'
  }
}