<template lang="pug">
#app
	component(v-bind:is="Layout.layout")
	networkConnection
	appWelcomeFlow
	notificationsHandler
	AppVersionModal

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import NetworkConnection from '@/components/shared/networkConnection.vue'
import AppWelcomeFlow from '@/components/shared/appWelcomeFlow/appWelcomeFlow.vue'
import NotificationsHandler from '@/components/shared/notificationsHandler.vue'
import AppVersionModal from '@/components/shared/appVersionModal.vue'
import { useLayoutStore } from '@/store/modules/layout'
import router from './router'

@Component({
	components: {
		NetworkConnection,
		AppWelcomeFlow,
		NotificationsHandler,
		AppVersionModal,
		'Admin': () => import('@/layouts/admin.vue'),
		'App': () => import('@/layouts/app.vue'),
		'FullPage': () => import('@/layouts/fullPage.vue')
	}
})
export default class Abc extends Vue {
	Layout = useLayoutStore()

	created() {
		this.checkForWelcomeMessage()
	}

	@Watch('$route.query')
	queryChange() {
		this.checkForWelcomeMessage()
	}

	checkForWelcomeMessage() {
		const type = this.$route.query.showMessage
		if (!type) return

		if (type === 'mobilePay-connect-success') {
			this.$bvToast.toast('Du kan nu modtage betalinger fra dine kunder via MobilePay', {
				title: 'MobilePay forbindelse oprettet',
				variant: 'success'
			})
		}

		this.$route.query.showMessage = ''
	}
}
</script>

<style lang="scss"></style>
