<template lang="pug">
div
  b-modal(
    :title="$t('components.shared.paymentInvoiceModal.title')"
    ref="payment-invoice-modal"
    title-tag="h4"
    centered
    hide-footer
    size="sm")
    div(v-if="state === 'Get'")
      validation-observer(ref='observer' v-slot='{ invalid }')
        b-form(@submit.prevent='paymentInvoiceLocal()' novalidate)
          b-form-group(:label="$t('input.namePersonOrCompany.label')")
            validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='paymentInvoice.variables.customTo.companyName'
                type='text' 
                :disabled="paymentInvoice.formDisabled")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

          b-form-group(:label="$t('input.streetNameAndNumber.label')")
            validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='paymentInvoice.variables.customTo.streetName'
                type='text' 
                :disabled="paymentInvoice.formDisabled")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

          b-form-group(:label="$t('input.zipAndCity.label')")
            validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='paymentInvoice.variables.customTo.zipAndCity'
                type='text' 
                :disabled="paymentInvoice.formDisabled")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

          b-form-group(:label="$t('input.vatNumber.label')")
            validation-provider(name='name' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='paymentInvoice.variables.customTo.vatNumber'
                type='text' 
                :disabled="paymentInvoice.formDisabled")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

          b-button.mt-3(
            variant="primary"
            block
            @click="paymentInvoiceLocal()"
            :disabled="paymentInvoice.isLoading") 
            span(v-if="paymentInvoice.isLoading")
              b-spinner.button-loading(small type="grow")
              span {{ $t('components.shared.paymentInvoiceModal.createButton.loading') }}
            span(v-else) {{ $t('components.shared.paymentInvoiceModal.createButton.init') }}

    div(v-else-if="state === 'Show'")
      b-button(
        block
        variant="primary"
        target="_blank"
        :href="invoiceUrl"
      ) {{ $t('components.shared.paymentInvoiceModal.invoiceLink') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetPaymentInvoice, GetPaymentInvoiceMutationVariables } from '@/graphql';
import { gql } from '@apollo/client/core';
import { SmartMutation } from '@/utils/smartMutation';

@Component({
  components: {

  },
  apollo: {
    me: {
      query: gql`
        query GetMe {
          me {
            user {
              id
              firstName
              lastName
              streetName
              zipCode
              city
            }
          }
        }
      `,
      update(data: { me: { user: { id: string, firstName: string, lastName: string, streetName: string, zipCode: string, city: string } } }) {
        this.paymentInvoice.variables.customTo = {
          companyName: `${data.me.user.firstName} ${data.me.user.lastName}`,
          streetName: data.me.user.streetName,
          zipAndCity: `${data.me.user.zipCode} ${data.me.user.city}`
        }
      }
    }
  }
})
export default class CompanyUsersUserView extends Vue {
  state: 'Get' | 'Show' = 'Get'
  invoiceUrl: null | string = null

  created() {
    this.invoiceUrl = null
    this.paymentInvoice.componentThis = this
  }

  show(paymentId: string) {
    this.reset()
    //@ts-ignore
    this.paymentInvoice.variables.payment = paymentId
    //@ts-ignore
    this.$refs['payment-invoice-modal'].show()
  }

  reset() {
    //@ts-ignore
    this.$refs['payment-invoice-modal'].hide()
    this.state = 'Get'
    this.invoiceUrl = null
  }

  async paymentInvoiceLocal() {
    const { paymentInvoice } = await this.paymentInvoice.mutate()
    this.invoiceUrl = paymentInvoice
    this.state = 'Show'
  }

  paymentInvoice = new SmartMutation({
    componentThis: null,
    mutation: GetPaymentInvoice,
    variables: (): GetPaymentInvoiceMutationVariables => {
      return {
        payment: '',
        customTo: {
          companyName: '',
          streetName: '',
          zipAndCity: ''
        }
      }
    }
  })


}
</script>

<style lang="sass" scoped>

</style>