export default {
  subTitle: 'Settings',
  pageTitle: 'Courts',
  description: "Here, you can create new courts and edit existing ones with ease. Customize details such as prices, names, and logos to ensure each court reflects your preferences.",

  createTitle: 'Create new',
  createCategory: 'Category',
  createType: 'Type',
  createResource: 'Court',

  noTypes: {
    title: 'No types',
    description: 'No types have been created for this category. Add one above.'
  },

  editType: 'Edit type',
  editCategory: 'Edit category',
  
  resources: {
    title: 'Courts',
    createResources: 'Add court',

    table: {
      logo: 'Logo',
      name: 'Name',
      location: 'Location',
      noResources: 'You have not created any courts for this type...',
      editResource: 'Edit court'
    }
  },

  prices: {
    title: 'Prices',
    createPrice: 'Add price',

    table: {
      startTime: 'Start time',
      endTime: 'End time',
      price: 'Price',
      days: 'Days',
      dates: 'Date',

      template: {
        days: 'days',
        dates: 'dates',
        isActiveTrue: 'Active',
        isActiveFalse: 'Not active',

        actions: {
          addDate: 'Add date',
          remove: 'Delete',

          addDatePopover: {
            description: 'Not created'
          }
        }
      }
    },

    resourceTypePriceDeleteSuccessToast: 'The price is removed'
  },

  openingHours: {
    titleWeekdays: 'Everyday opening hours',
    titleWeekend: 'Weekend opening hours',
    opening: 'Opening',
    closing: 'Closing'
  },

  price: {
    aPrice: {
      label: 'A price',
      placeholder: 'Price for A time'
    },
    bPrice: {
      label: 'B price',
      placeholder: 'Price for B time'
    },

    aPriceWeekdaysIntervals: {
      title: 'Everyday A prices intervals',
      createInterval: 'Add interval',
      noIntervals: 'No A intervals in everdays',
      createPopover: {
        title: 'Add A interval',
        start: 'Start',
        end: 'End',
        create: 'Add interval'
      }
    },

    aPriceWeekendIntervals: {
      title: 'Weekend A prices intervals',
      createInterval: 'Add interval',
      noIntervals: 'No A intervals in weekends',
      createPopover: {
        title: 'Add A interval',
        start: 'Start',
        end: 'End',
        create: 'Add interval'
      }
    }
  },

  categoryModal: {
    updateTitle: 'Update category',
    
    accessMinutsBeforeBooking: 'Minutes access before booking',
    accessMinutesAfterBooking: 'Minutes access after booking',
    maxDaysCreateResourceBookingAhead: 'Days into the future users can book',
    maxGapIntervalsCount: 'Maximum number of gaps in between bookings (0 = no gaps, 1 = infinite gaps)',
    durationIntervalMinutes: 'Booking intervals in minutes (normal 30 min)',

    durationMinutesIntervals: {
      title: 'Booking intervals',
      createInterval: 'Add interval',
      minutes: 'minutes',
      hours: 'hours',
      noIntervals: 'No intervals for booking',
      addDuractionIntervalPopover: {
        title: 'Add interval',
        createButton: 'Add interval'
      }
    },

    updateCategoryButton: 'Update category',
    updateCategoryLoadingButton: 'Updating category...',

    succesToast: 'The category is updated'
  },

  updateTypeModal: {
    title: 'Update type',
    description: 'This is not yet created',

    updateTypeButton: 'Update type',
    updateTypeLoadingButton: 'Updating type...',

    succesToast: 'The type is updated'
  },

  createResourceTypePriceModal: {
    title: 'Create court price',
    priceInfo: 'IMPORTANT: The price below is per. half an hour. So if you want a new price at e.g. 100 per hour, you must write 50 below.',
    newPrice: {
      label: 'New price'
    },
    datesInfo: "In the date ranges you select below, the above price will apply. If you do not fill in some dates, all days from now on will be affected by your new price.",
    addDateButton: 'Add date',

    addPriceDateRangePopover: {
      title: 'Add date',
      createDateButton: 'Create date'
    },

    createPriceButton: 'Create price',
    createPriceLoadingButton: 'Creating price...',

    successToast: 'The price is created'
  },

  createResourceTypeModal: {
    title: 'Create type',
    description: 'You are now creating a new type. Fill in the fields below.',

    createTypeButton: 'Create type',
    createTypeLoadingButton: 'Creating type...',

    succesToast: 'The type is created'
  },

  updateResourceModal: {
    title: 'Change court info',
    updateResourceButton: 'Update court',
    updateResourceLoadingButton: 'Updating court...',
    activateCourt: 'Activate court for users',
    active: 'Court is active',
    notActive: 'Court is not active'
  },

  createResourceModal: {
    title: 'Create court',
    description: 'You are now creating a new court. Fill in the fields below.',

    createResourceButton: 'Create court',
    createResourceLoadingButton: 'Creating court...',

    succesToast: 'The court is created'
  },

  createCategoryModal: {
    title: 'Create category',
    description: 'You are now creating a new category. Fill in the fields below.',
    createResourceCategoryButton: 'Create category',
    createResourceCategoryLoadingButton: 'Creating category...',

    succesToast: 'The category has been created.'
  }

}