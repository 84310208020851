export default {
  mode: {
    manual: 'User enters the code',
    automatic: 'Automatically added',
    hidden: 'Hidden'
  },

  discountType: {
    percent: 'Percent',
    amount: 'Amount',
    bookingHours: 'Booking hours'
  },

  couponFormInputs: {
    inConnectionToResourceBookingTitle: 'In connection with court booking',
    slug: {
      label: 'Code that users has to enter (must not include spaces)',
      placeholder: 'winter25'
    },
    discountType: {
      label: 'Choose a discount type',
      amountOff: {
        label: 'Discount in money'
      },
      percentOff: {
        label: 'Discount in percent'
      },
      bookingHoursOff: {
        label: 'Discount in booking hours'
      },
    },
    couponMode: {
      label: 'Discount mode'
    },
    endDate: {
      label: 'Last usage date (empty for none)',
      placeholder: 'Last date for usage'
    },
    discountIsOnlyForBuyer: {
      label: 'Discount is for buyer only'
    },
    connectedToUserGroups: {
      label: 'Choose user groups'
    },
    maximumUsages: {
      label: 'Max usage (0 for unlimited)'
    },
    maximumUsagesPrCustomer: {
      label: 'Max usage per user (0 for unlimited)'
    },
    maximumUsagesPrCustomerEvents: {
      label: 'Max event bookings with discount'
    },
    maximumUsagesPrCustomerPrMonthEvents: {
      label: 'Max event bookings with discount is per month'
    },
    resourceBookingEndDateAfter: {
      label: 'The booking must have start date AFTER the following date (empty for none)',
      placeholder: 'Date'
    },
    resourceBookingEndDateBefore: {
      label: 'The booking must have end date BEFORE the following date (empty for none)',
      placeholder: 'Date'
    },
    maximumResourceBookingHoursPrMonthUsage: {
      label: 'Max hours per month total'
    },

    timeRanges: {
      title: 'Time intervals',
      addTimeRangeButton: 'Add time interval',
      popover: {
        title: 'Add time interval',
        createTimeRangeButton: 'Create time interval'
      }
    }
  },
}