export function currencyFormatter(value: number, currency: string): string {
	if (!currency) {
		console.warn("Invalid or missing currency code");
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
		}).format(value);
	} else {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: currency,
		}).format(value);
	}
}
