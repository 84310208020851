import { SupportedCountries } from "@/graphql";
import { i18n } from "@/locales/setupI18n";

export function getCountryOptions() {
    return [
        { value: SupportedCountries.Dk, label: i18n.t('common.country.dk').toString() },
        { value: SupportedCountries.Se, label: i18n.t('common.country.se').toString() },
        { value: SupportedCountries.No, label: i18n.t('common.country.no').toString() },
        { value: SupportedCountries.Fi, label: i18n.t('common.country.fi').toString() },
        { value: SupportedCountries.De, label: i18n.t('common.country.de').toString() },
        { value: SupportedCountries.Es, label: i18n.t('common.country.es').toString() },
        { value: SupportedCountries.Gb, label: i18n.t('common.country.gb').toString() },
        { value: SupportedCountries.At, label: i18n.t('common.country.at').toString() }
    ]
}