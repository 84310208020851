<template>
<div>
  <stripe-elements
    :stripe-key="stripeKey"
    :elements-options="elementsOptions"
    ref="elms"
    #default="{ elements }"
  >
    <stripe-element
      type="card"
      ref="card"
      :elements="elements"
      @change="complete = $event.complete"
    />
  </stripe-elements>
  <b-button @click="pay()" size="lg" variant="primary" class="pay-button">
    {{ $t('components.app.pay.index.payWith') }} {{ $t('components.app.pay.index.paymentOptions.creditCard') }}
  </b-button>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { } from '@/graphql'
//@ts-ignore
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayStripeStore } from '@/store/modules/pay/stripe'


// TODO: boy scout rules!!!
@Component({
  components: {
    StripeElements,
    StripeElement,
  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  payStore = usePayStore()
  payStripeStore = usePayStripeStore()

  //* https://github.com/cgrisar/vue-stripe-elements/commit/76a1860325a893f998e8fb1e49d08c2ad05abbd8

  complete = false
  stripeKey = process.env.STRIPE_KEY
  instanceOptions = {
    // https://stripe.com/docs/js/initializing#init_stripe_js-options
  }

  elementsOptions = {
    // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  }

  cardOptions = {
    // https://stripe.com/docs/stripe.js#element-options
  }

  async pay() {
    const groupComponent = this.$refs.elms
    const cardComponent = this.$refs.card
    //@ts-ignore
    const card = cardComponent.stripeElement
    //@ts-ignore
    const stripe = groupComponent.instance

    await this.payStripeStore.createCardPaymentWeb(stripe, card)
  }
}
</script>
<style lang="scss" scoped>
.pay-button {
  margin-top: 1rem;
  width: 100%;
}
</style>