export default {
  subTitle: 'User',
  isDeleted: 'This user has been deleted',

  infoTitle: 'Informations',
  infoCards: {
    paymentsSum: 'Payment sum',
    resourceBookingsCount: 'Bookings in total',
    pointsAmount: 'Points',
    showPointAccount: 'View point account'
  },

  bookingRatio: {
    title: 'Booking ratio',
    infoHover: 'The figures below tell the number of bookings per. week.',
    resourceBookingRatioChartShow: {
      one: 'All life',
      two: 'Last 2 months',
      three: 'Last 4 months'
    },
    pastWeekRatio: 'Last 7 days',
    averageRatio: 'Avg.'
  },

  map: {
    title: 'User location',
    subTitle: '(Random location within a radius of 2 km.)'
  },

  info: {
    userSignInMethod: 'Sign in method',
    accessCode: 'Door access code'
  },

  actions: {
    addOrRemovePoints: 'Add / remove points',
    stripeCustomer: 'View user in Stripe',
    sendUserPasswordResetEmail: 'Send password reset mail',
    deleteUser: 'Delete user'
  },

  userGroup: {
    title: 'User groups',
    addUserGroup: 'Add',
    none: {
      title: 'No user groups',
      text: 'This user is not a member of any groups.'
    }
  },

  recurringBookings: {
    title: 'Recurring bookings',
    none: {
      title: 'No recurring bookings',
      text: 'This user is not registered for any recurring bookings.'
    }
  },

  subscriptions: {
    title: 'Memberships',
    add: 'Add',
    none: {
      title: 'No memberships',
      text: 'This user is not a member of any memberships.'
    }
  },

  userPlatformConnections: {
    title: 'Connected platforms',
    signIn: 'Sign in'
  },

  bookingTab: {
    title: 'Bookings',
    resourceBookingsCount: 'Bookings in total',
    futureResourceBookingsCount: 'Future bookings'
  },

  paymentsTab: {
    title: 'Payments',
    paymentsSumChart: 'Revenue per week',
    paymentsSum: 'Payments sum',
    paymentsSumPeriod: 'This week'
  },

  updateUserModal: {
    title: 'Update user information'
  },

  createSubscriptionModal: {
    title: 'Add membership',
    selectSubscriptionProduct: {
      label: 'Choose a membership',
      placeholder: 'Select a membership...'
    },
    addEndDate: 'Add end date',
    endDatePladeholder: 'End dage for membership',
    addSubscriptionButton: 'Add membership',
    successToast: 'The membership has been created',
    shouldBePaidSubscription: 'User should pay for subscription with saved card (if off the subscription will be free)'
  },

  addUserToGroupModal: {
    title: 'Add user to user group',

    addToUserGroupButton: 'Add to group',
    addToUserGroupLoadingButton: 'Adding to group...',
    successToast: 'The user has been added'
  },

  removeUserFromGroupSuccessToast: 'The user is deleted',

  deleteUser: {
    sure: {
      text: 'Are you sure about this? When you delete a user, the person will be deleted across the platform and the person will not be able to log in again. This feature is not always functional - will be improved in the future',
      title: 'Deleting user',
      yes: 'Delete user'
    },
    withErrorToast: 'The user could not be deleted',
    successToast: 'The user is delete'
  },

  userPasswordReset: {
    successToast: {
      title: 'Email is sent',
      text: 'Success'
    }
  }
}