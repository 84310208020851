import { SupportedCountries } from "@/graphql";

export function getPhoneCountryCodes(): { country: SupportedCountries, flag: string, code: string }[] {
    return [
        { country: SupportedCountries.Dk, flag: '🇩🇰', code: '+45' },
        { country: SupportedCountries.Se, flag: '🇸🇪', code: '+46' },
        { country: SupportedCountries.No, flag: '🇳🇴', code: '+47' },
        { country: SupportedCountries.De, flag: '🇩🇪', code: '+49' },
        { country: SupportedCountries.Fi, flag: '🇫🇮', code: '+358' },
        { country: SupportedCountries.Es, flag: '🇪🇸', code: '+34' },
        { country: SupportedCountries.Gb, flag: '🇬🇧', code: '+44' },
        { country: SupportedCountries.At, flag: '🇦🇹', code: '+43' }
    ]
}