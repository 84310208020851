<template>
  <div>
    <b-card
      class="bg-light shadow-none card-no-border cursor-pointer hover-scale"
      @click="$refs['subscription-product-modal'].show(product.id)"
      style=""
    >
      <b-card
        v-if="
          product.userProductSubscription &&
          product.userProductSubscription.status === 'Active'
        "
        class="mb-4 bg-light shadow-none card-no-border card-no-padding"
      >
        <h4 class="m-0">
          {{ $t("pages.user.shop.userProductSubscription.isActive") }}
        </h4>
      </b-card>
      <b-card
        v-if="
          product.userProductSubscription &&
          product.userProductSubscription.status === 'ToCancele'
        "
        class="mb-4 bg-light shadow-none card-no-border card-no-padding"
      >
        <h4 class="m-0">
          {{ $t("pages.user.shop.userProductSubscription.isToCancele") }}: {{ $moment(product.userProductSubscription.cancelDate).format("DD.MM.YYYY") }}
        </h4>
      </b-card>
      <b-card
        v-if="
          product.userProductSubscription &&
          product.userProductSubscription.status === 'PendingStatus'
        "
        class="mb-4 bg-light shadow-none card-no-border card-no-padding"
      >
        <h4 class="m-0">
          {{ $t("pages.user.shop.userProductSubscription.pendingStatus") }}
        </h4>
      </b-card>
      <h4>{{ product.name }}</h4>
      <p class="list-style opacity-80" v-html="product.description"></p>
      <hr />
      <b-row align-v="center">
        <b-col cols="auto">
          <div>
            <h2 class="m-0 text-monospace">
              {{ currencyFormatter(product.product.price, userCurrency) }}
            </h2>
            <small class="opacity-80 m-0">
              <span v-if="product.paymentTimesPrYear === 12">{{
                $t(
                  "pages.user.shop.userProductSubscription.subscriptionProduct.perMonth"
                )
              }}</span>
              <span v-if="product.paymentTimesPrYear === 1">{{
                $t(
                  "pages.user.shop.userProductSubscription.subscriptionProduct.perYear"
                )
              }}</span>
            </small>
          </div>
        </b-col>
        <b-col class="border-left">
          <div class="mb-2">
            <small class="opacity-80 m-0">{{
              $t(
                "pages.user.shop.userProductSubscription.subscriptionProduct.signUpFee"
              )
            }}</small>
            <p class="m-0">
              {{
                currencyFormatter(product.signUpFeeProduct.price, userCurrency)
              }}
            </p>
          </div>
          <div class="mb-2">
            <small class="opacity-80 m-0">{{
              $t(
                "pages.user.shop.userProductSubscription.subscriptionProduct.paymentDiscountType"
              )
            }}</small>
            <p class="m-0" v-if="product.paymentDiscountType === 'Percentage'">
              {{ product.paymentDiscountPercentage }}%
            </p>
            <p class="m-0" v-if="product.paymentDiscountType === 'Amount'">
              {{
                currencyFormatter(product.paymentDiscountAmount, userCurrency)
              }}
            </p>
            <p
              class="m-0"
              v-if="product.paymentDiscountType === 'AmountPrBookingInterval'"
            >
              {{
                currencyFormatter(
                  product.paymentDiscountAmountPrBookingInterval * 2,
                  userCurrency
                )
              }}
              {{
                $t(
                  "pages.user.shop.userProductSubscription.subscriptionProduct.prHour"
                )
              }}
            </p>
          </div>
          <div class="mb-0">
            <small class="opacity-80 m-0">{{
              $t(
                "pages.user.shop.userProductSubscription.subscriptionProduct.maxActiveBookings.title"
              )
            }}</small>
            <p class="m-0" v-if="product.maxActiveBookings === 0">
              {{
                $t(
                  "pages.user.shop.userProductSubscription.subscriptionProduct.maxActiveBookings.unlimited"
                )
              }}
            </p>
            <p class="m-0" v-else>{{ product.maxActiveBookings }}</p>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <SubscriptionProductModal ref="subscription-product-modal" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { currencyFormatter } from "@/utils/currencyFormatter";
import SubscriptionProductModal from "@/components/app/subscriptionProduct.vue";
import { useLocationStore } from "@/store/modules/location";

export default defineComponent({
  components: {
    SubscriptionProductModal,
  },
  name: "UserProductSubscription",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    currencyFormatter,
  },
  setup() {
    const locationStore = useLocationStore();
    const userCurrency = locationStore.userLocation.currency;

    return {
      userCurrency,
    };
  },
});
</script>

<style scoped>
.card-status {
  padding: 15px 0px!;
}
</style>