export default {
  
  week: 'Week',
  weekItem: 'Week',

  type: 'Type',

  location: 'Location',

  readMoreButton: 'Read more',

  noWorkshops: {
    title: 'No events this week',
    description: 'Try to choose another week.'
  }
}