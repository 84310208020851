<template lang="pug">

.input-group.input-group-merge.only-prepend
  b-form-select.form-control-prepended(
    id="lang-switcher"
    v-model="selectedLang"
    :options="langOptions"
    @change="updateLocale")
  .input-group-prepend
    .input-group-text
      span.fe.fe-globe

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { UserUpdate, UserUpdateMutationVariables } from '@/graphql'
import { SmartMutation } from '@/utils/smartMutation';
import { LocaleType, useLocaleStore } from '@/store/modules/locale';
import { useUserStore } from '@/store/modules/user';

@Component({
  components: {

  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {
  @Prop({ required: false, type: Boolean }) private showLabel!: boolean

  Locale = useLocaleStore()
  User = useUserStore()

  selectedLang: null | LocaleType = null

  created() {
    this.updateUserLanguageMutation.componentThis = this
  }

  get langOptions(): { text: string, value: LocaleType }[] {
    return [
      { text: this.$t('common.staticTemp.localeType.danishDk') as string, value: LocaleType.DaDk },
      { text: this.$t('common.staticTemp.localeType.englishGb') as string, value: LocaleType.EnGb },
      { text: this.$t('common.staticTemp.localeType.germanDe') as string, value: LocaleType.DeDe },
    ]
  }

  @Watch('$i18n.locale', { immediate: true })
  currentLocaleChange(newLocale: LocaleType) {
    this.selectedLang = newLocale

    this.$emit('selectedLang', newLocale)
  }


  async updateLocale(locale: LocaleType) {
    this.Locale.changeLocale(locale)

    if (this.User.user) {
      const languageEnum = locale.trim().replace('-', '_')
      await this.updateUserLanguageMutation.mutate({ data: { language: languageEnum } })
    }
  }

  updateUserLanguageMutation = new SmartMutation({
    componentThis: null,
    mutation: UserUpdate,
    variables: (): UserUpdateMutationVariables => {
      return {
        data: {
          language: null
        }
      }
    }
  })
}
</script>

<style lang="sass" scoped>

</style>