export default {
  goBack: 'Go back',
  yourBooking: 'Your booking',

  info: {
    title: 'Info',

    bookingIsCancled: {
      title: 'This booking has been canceled'
    },
  
    isInvited: {
      youAreInvitedBuy: 'You have been invited by',
      toThisBooking: 'to this booking'
    },
  
    info: {
      when: 'WHEN',
      time: 'TIME',
      where: 'WHERE',
      resource: 'COURT',
      status: 'STATUS',
      accessCode: 'ACCESS CODE',
      additionalService: 'ADDITIONAL SERVICE',
      to: 'to'
    },
  
    actions: {
      share: 'Share booking with friends',
      viewPayments: 'View payments and distribution',
      cancelBooking: 'Cancel booking'
    },
  
    canNotBeCanceled: {
      isOwner: {
        title: 'Cannot be canceled',
        description: 'It is not possible to cancel this booking as there are less than {0} hours to start booking.'
      },
      isNotOwner: {
        title: 'Cannot be canceled',
        description: 'As you have been invited to this booking, it is only possible for the person who created the booking to cancel you as a participant.'
      }
    },

    cancelBooking: {
      areYouSure: {
        text: 'Are you sure you want to cancel this booking?',
        title: 'Cancel booking - Are you sure?',
        yes: 'Yes',
        no: 'Cancel'
      },
      successToast: 'The booking has been canceled'
    }
  },

  attendees: {
    title: 'Participants',
    thereIsAdded: 'Added',
    off: 'out of',
    total: 'possible participants',

    attendeesList: {
      you: '(Yourself)',
      addFriend: 'Add friend',
      remove: 'Remove player',
    },

    addAttendee: 'Add participants',

    deleteAttendeeSuccessToast: 'Your friend has been removed.',
    createFreiendshipToast: 'Your friend has been added.',

    buyOutAttendee: {
      info: {
        title: 'Remove participant with discount from booking - Information',
        discountText: 'The participant you wish to remove has a discount of',
        infoText: 'that was used on the booking. To remove the participant from the booking, the discounted amount needs to be paid.',
        continue: 'Continue',
        cancel: 'Cancel'
      },      
      success: 'The discount has been payed and the participant has been removed from your booking!'
    }

  },

  video: {
    title: 'Video'
  },

  ranking: {
    title: 'Ranking',
    description: 'We are working on a ranking system where you can add your matches and achieve a level.'
  }
}