export default {
  title: 'Discount codes',
  description: "Create and update discount codes here! Codes can be set to give a discount in either amount, percentage or booking hours.",
  createButton: 'Create discount code',

  noCoupons: 'No discount codes',
  couponTable: {
    hours: 'hours',
    endDate: 'End date',
    maxUsagesPrUser: 'Max usage per customer',
    maxUsages: 'Max usage in total'
  },

  createCouponModal: {
    title: 'Create discount code',
    description: 'You are about to create a discount code. Fill the inputs below.',

    createCouponButton: 'Create discount code',
    createCouponLoadingButton: 'Creating discount code...',
    userGroups: "User groups",

  }
}