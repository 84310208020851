<template lang="pug">
.container-fluid.py-4.safe-area-inset-top
	h1.pt-2.pb-3.text-center(v-if="locationUserDefault && locationUserDefault.company && locationUserDefault.company.pointAccountUsingFranchise") {{ locationUserDefault.company.franchise.name }}&nbsp;
	h1.pt-2.pb-3.text-center(v-else) {{ locationUserDefault && locationUserDefault.company ? locationUserDefault.company.name : '' }}&nbsp;

	div.mb-5
		h2.m-0.mb-3 {{ $t('pages.user.shop.workshops.title') }} 
		p {{ $t('pages.user.shop.workshops.description') }}
		b-button.py-3(
			variant="outline-primary"
			size="md"
			block
			:to="{ name: 'User.Workshops' }") {{ $t('pages.user.shop.workshops.viewButton') }}

	div.mb-5(v-if="pointProducts.length")
		header
			b-row.justify-content-center
				b-col(auto)
					h2.m-0 {{ $t('pages.user.shop.points.title') }}

		section.mt-3
			p {{ $t('pages.user.shop.points.description') }}

				p.m-0 <b class="text-body" style="font-size: 16px">{{ pointAmount }} {{ $t('pages.user.shop.points.points') }}</b> {{ $t('pages.user.shop.points.at') }}&nbsp;
					b.text-body(v-if="locationUserDefault && locationUserDefault.company.pointAccountUsingFranchise") {{ locationUserDefault.company.franchise.name }}&nbsp;
					b.text-body(v-else) {{ locationUserDefault.company.name }}&nbsp;

			b-overlay(
				:show="$apollo.queries.pointProducts.loading"
				variant="white"
				spinner-variant="primary")
				.point-product-container
					div(v-for="pointProduct in pointProducts"
						@click="$refs['buy-point-modal'].show(pointProduct.id)"
						:key="pointProduct.id").point-product-card
						pointProduct(:title="pointProduct.title" :amount="pointProduct.pointsAmount" :price="pointProduct.product.price" :discount-percent="pointProduct.discountPercent")

	div(v-if="userProductSubscriptionsActive.length > 0")
		h2.m-0.mb-3 {{ $t('pages.user.shop.userProductSubscription.activeTitle') }}

		b-overlay(
			:show="$apollo.queries.subscriptionProducts.loading"
			variant="white"
			spinner-variant="primary")

		UserProductSubscription(
			v-for="product in userProductSubscriptionsActive"
			:key="product.id"
			:product="product"
			@click="$refs['subscription-product-modal'].show(product.id)")

	div(v-if="userProductSubscriptionsPending.length > 0")
		h2.m-0.mb-3 {{ $t('pages.user.shop.userProductSubscription.pendingTitle') }}

		b-overlay(
			:show="$apollo.queries.subscriptionProducts.loading"
			variant="white"
			spinner-variant="primary")

		UserProductSubscription(
			v-for="product in userProductSubscriptionsPending"
			:key="product.id"
			:product="product"
			@click="$refs['subscription-product-modal'].show(product.id)")

	div
		h2.m-0.mb-3 {{ $t('pages.user.shop.userProductSubscription.title') }}

		b-overlay(
			:show="$apollo.queries.subscriptionProducts.loading"
			variant="white"
			spinner-variant="primary")

			p.mb-0.text-muted(v-if="!subscriptionProductsFiltered.length && locationUserDefault") {{ $t('pages.user.shop.userProductSubscription.notPossible', [ locationUserDefault.name ]) }} 

			b-card.bg-light.shadow-none.card-no-border.p-0(v-if="subscriptionProductsFiltered.length && !stripeV2Store.isStripePaymentElementsCompatible")
				b-row
					b-col(cols=1)
						i.fe.fe-alert-circle
					b-col
						h5.mb-0.mt-2 {{ $t('pages.user.shop.userProductSubscription.activationWarning') }}
			div.my-4(v-if="activeSubscription && subscriptionProductsFiltered.length > 1" style="width: 100%")
				h4 {{ $t('pages.user.shop.userProductSubscription.upgrade.title') }}
				p.mb-0 {{ $t('pages.user.shop.userProductSubscription.upgrade.text') }}

			b-card.bg-light.shadow-none.card-no-border.cursor-pointer.hover-scale(
				v-for="product in subscriptionProductsFiltered"
				:key="product.id"
				@click="$refs['subscription-product-modal'].show(product.id)"
				style="")

				b-card.mb-4.bg-light.shadow-none.card-no-border.card-small-padding(v-if="product.userProductSubscription && product.userProductSubscription.status === 'ToCancele'")
					h4.m-0 {{ $t('pages.user.shop.userProductSubscription.isToCancele') }}

				h4 {{ product.name }}
				p.list-style.opacity-80(v-html="product.description")

				hr

				b-row(align-v="center")
					b-col(cols="auto")
						div
							h2.m-0.text-monospace {{ currencyFormatter(product.product.price, LocationStore.userLocation.currency) }}
							small.opacity-80.m-0
								span(v-if="product.paymentTimesPrYear === 12") {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.perMonth') }}
								span(v-if="product.paymentTimesPrYear === 1") {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.perYear') }}


					b-col.border-left
						div.mb-2
							small.opacity-80.m-0 {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.signUpFee') }}
							p.m-0 {{ currencyFormatter(product.signUpFeeProduct.price, LocationStore.userLocation.currency) }}

						// div(v-if="product.couponOnOtherPayments")
							small.opacity-80.m-0 {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.couponOnOtherPayments') }}
							p.m-0 {{ product.couponOnOtherPayments.percentOff }}%

						div.mb-2
							small.opacity-80.m-0 {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.paymentDiscountType') }}
							p.m-0(v-if="product.paymentDiscountType === 'Percentage'") {{ product.paymentDiscountPercentage }}%
							p.m-0(v-if="product.paymentDiscountType === 'Amount'") {{ currencyFormatter(product.paymentDiscountAmount, LocationStore.userLocation.currency) }}
							p.m-0(v-if="product.paymentDiscountType === 'AmountPrBookingInterval'") {{ currencyFormatter(product.paymentDiscountAmountPrBookingInterval * 2, LocationStore.userLocation.currency) }} {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.prHour') }}

						div.mb-0
							small.opacity-80.m-0 {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.maxActiveBookings.title') }}
							p.m-0(v-if="product.maxActiveBookings === 0") {{ $t('pages.user.shop.userProductSubscription.subscriptionProduct.maxActiveBookings.unlimited') }}
							p.m-0(v-else) {{ product.maxActiveBookings }}

	ChangeUserDefaultLocation(
		hidden
		ref="changeUserDefaultLocation")

	BuyPointsModal(ref="buy-point-modal")

	SubscriptionProductModal(ref="subscription-product-modal")

</template>

<script lang="ts">
import BuyPointsModal from "@/components/app/buyPointsModal.vue";
import ChangeUserDefaultLocation from "@/components/app/changeUserDefaultLocation.vue";
import SubscriptionProductModal from "@/components/app/subscriptionProduct.vue";
import pointProduct from "./shop/pointProduct.vue";

import { Component, Vue } from "vue-property-decorator";
import { GetPointProducts, GetLocationUserDefaultQuery, GetPointProductsQuery, GetSubscriptionProducts, GetSubscriptionProductsQuery, GetPointAmount, GetPointAmountQuery, UserProductSubscriptionStatus, GetLocation } from "@/graphql";
import { useLocationStore } from "@/store/modules/location";
import { usePayV2StripeStore } from "@/store/modules/payV2/stripeV2";
import { currencyFormatter } from "@/utils/currencyFormatter";
import UserProductSubscription from "./shop/userProductSubscription.vue";

@Component({
	components: {
		BuyPointsModal,
		ChangeUserDefaultLocation,
		SubscriptionProductModal,
		pointProduct,
		UserProductSubscription
	},
	methods: {
		currencyFormatter
	},
	apollo: {
		locationUserDefault: {
			query: GetLocation,
			variables() {
				return {
					id: this.LocationStore.currentUserLocation?.id,
				};
			},
			skip() {
				return !this.LocationStore.currentUserLocation?.id;
			},
			update(data) {
				this.LocationStore.getUserLocation();

				return data.location;
			},
		},
		pointProducts: {
			query: GetPointProducts,
			update(data: GetPointProductsQuery) {
				return [...data.pointProducts.data].sort((a, b) => {
					const orderA = Number(a.order) || Infinity; // Treats `0`, `null`, etc., as the highest value
					const orderB = Number(b.order) || Infinity;

					return orderA - orderB;
				});
			},
		},
		pointAmount: {
			query: GetPointAmount,
			update(data: GetPointAmountQuery) {
				return data.me.pointAccount.pointsAmount;
			},
		},
		subscriptionProducts: {
			query: GetSubscriptionProducts,
			update(data: GetSubscriptionProductsQuery) {
				if (this.openSubscriptionProductId) {
					//@ts-ignore
					this.$refs["subscription-product-modal"].show(this.openSubscriptionProductId);
					this.openSubscriptionProductId = null;
				}
				return data.subscriptionProducts.data;
			},
		},
	},
})
export default class Shop extends Vue {
	LocationStore = useLocationStore();

	locationUserDefault: null | GetLocationUserDefaultQuery["locationUserDefault"] = null;

	pointAmount = 0;

	subscriptionProducts: GetSubscriptionProductsQuery["subscriptionProducts"]["data"] = [];

	stripeV2Store = usePayV2StripeStore()

	get subscriptionProductsFiltered(): GetSubscriptionProductsQuery["subscriptionProducts"]["data"] {
		return this.subscriptionProducts.filter((x) => x.userAllowedToSignUp || x.userProductSubscription && x.userProductSubscription.status !== "Active" && x.userProductSubscription.status !== "ToCancele"
			&& x.userProductSubscription.status !== "PendingStatus");
	}

	get userProductSubscriptionsActive(): GetSubscriptionProductsQuery["subscriptionProducts"]["data"] {
		return this.subscriptionProducts.filter((x) => x.userProductSubscription && (x.userProductSubscription.status === "Active" || x.userProductSubscription.status === "ToCancele"));
	}

	get userProductSubscriptionsPending(): GetSubscriptionProductsQuery["subscriptionProducts"]["data"] {
		return this.subscriptionProducts.filter((x) => x.userProductSubscription && (x.userProductSubscription.status === "PendingStatus"));
	}

	get activeSubscription() {
		return this.subscriptionProductsFiltered.find((x) => x.userProductSubscription && x.userProductSubscription.status === UserProductSubscriptionStatus["Active"]);
	}

	openSubscriptionProductId: null | string = null;

	created() {
		this.$nextTick(() => {
			if (!this.LocationStore.currentUserLocation) {
				//@ts-ignore
				this.$refs["changeUserDefaultLocation"].show();
			}
		});
		this.$watch(
			() => this.LocationStore.currentUserLocation,
			(newLocation, oldLocation) => {
				if (!newLocation && oldLocation) {
					//@ts-ignore
					this.$refs["changeUserDefaultLocation"].show();
				}
			}
		);

		if (this.$route.params.openSubscriptionProductId) {
			this.openSubscriptionProductId = this.$route.params.openSubscriptionProductId;
		}
	}

	pointProducts: GetPointProductsQuery["pointProducts"]["data"] = [];
}
</script>

<style lang="scss" scoped>
.point-product-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;

	justify-content: center;
}

.point-product-card {
	min-width: 120px;
	max-width: 280px;
	min-height: 120px;
	max-height: 120px;
}

.list-style {

    ::v-deep ol {
        list-style-type: decimal !important;
        list-style-position: inside !important;
        padding-left: 1em;
    }

    ::v-deep ul {
        list-style-type: disc !important;
        list-style-position: inside !important;
        padding-left: 1em;
    }
        
    ::v-deep li {
        margin-bottom: 0.5em;
    }
}
</style>
