import { defineStore } from "pinia";
import { apolloClient } from "@/plugins/apollo";
import { AdditionalService, GetRanglistenSignUpLink } from "@/graphql";
import { useUserStore } from "./user";
import { useLocationStore } from "./location";
import { usePayStore } from "./pay/pay";

interface AdditionalServiceState {
  selectedServices: string[];
  disabledServices: string[];
  selectedServiceId: string;
  currentService: AdditionalService | null;
  infoAdditionalService: string;
}

export const useAdditionalServiceStore = defineStore({
  id: "AdditionalService",

  state: (): AdditionalServiceState => ({
    selectedServices: [],
    disabledServices: [],
    selectedServiceId: "",
    currentService: null,
    infoAdditionalService: "",
  }),

  actions: {
    isServiceDisabled(service: AdditionalService) {
      return this.disabledServices.includes(service.id);
    },
    isServiceSelected(service: AdditionalService) {
      return this.selectedServices.some((s) => s === service.id);
    },
    async checkServiceCapacity(additionalServices: any) {
      const payStore = usePayStore();

      for (let service of additionalServices) {
        // Check if additional service has reached its capacity for chosen time slot
        const isCapacityReached = await payStore.checkAdditionalServiceCapacity(
          service.id
        );

        if (isCapacityReached) {
          // If the capacity is reached, add the service to disabledServices.
          this.disabledServices.push(service.id);
        }
      }
    },
  },
});
