export default {
  
  noSelectedResourceCategory: {
    title: 'Create a booking',
    description: 'You have the opportunity to choose what you would like to book',

    noResourceCategories: {
      title: 'Nothing to book here...',
      description: 'This company has not added any items to book'
    }
  },

  type: 'Type',
  hours: 'Hours',
  date: 'Date',

  maxBookableDays: 'Max bookable days',

  hourtText: {
    hour: 'hour',
    hours: 'hours'
  },

  resourcesText: {
    resource: 'court',
    resources: 'courts'
  },

  selectionText: {
    hereIs: '',
    date: 'd.',
    with: 'with',
    hours: 'hours',
    play: 'play at'
  },

  changeLocation: 'Change location',
  viewBookingTable: 'Show overview',

  dynamicPricing: {
    title: '📉 Here we use dynamic pricing',
    descriptionOne: 'Dynamic prices help to define a price based on demand. This means that the price can rise at high demand and fall at low demand.',
    descriptionTwo: 'The purpose of dynamic pricing is to create better conditions for more users to play padel.',
    descriptionStrong: 'All to spread this amazing sport so more people can play padel!',
    viewButton: 'See booking slots'
  },

  missingInfo: {
    title: 'Missing information',
    description: 'Some information above needs to be added...'
  },

  timeAvailable: {
    courtsAble: 'available',
    pricePoints: 'points',

    noOptions: {
      title: 'No available options',
      description: 'Try a different duraction, date or type.'
    }
  },

  bookingTableModal: {
    close: 'Close window'
  },

  paymentModal: {
    changeBooking: 'Change booking',
    changeResource: 'Change court',
    sumText: {
      date: 'd.',
      from: 'from',
      time: 'time',
      to: 'to',
      hours: 'h'
    },
    price: 'Price',
    attendees: {
      title: 'Participants',
      whatIsThis: 'What is this?',
      notNeeded: {
        one: 'This is not a necessity.',
        two: 'Users you add below will simply be notified of the booking and make use of their possible membership.'
      },
      you: 'You',
      subscription: 'Member',
      discount: 'Discount',
    },
    additionalServices: {
      title: 'Additional services',
      descriptionInfoRequired: 'To use this service during your booking, we require more information from you. Please fill in the following required information: ',
      NameChild: 'Name of child to be celebrated: ',
      AgeChild: 'Age of child to be celebrated:'
    }
  },

  selectResourceModal: {
    title: 'Choose court',
    changeTime: 'Change time',
    chooseRandom: 'Select random'
  },

  attendeesResourceBookingModal: {
    title: 'Add participant',
    close: 'Close',
    youCanAdd: 'To this booking you can add up to',
    youHaveChoosen: 'other users. You have choosen',
    outOff: 'out of',
    possible: 'possible.',
    addFriend: 'Add a new friend',

    noFriends: {
      title: 'You have not added any friends',
      description: 'To add participants to a booking, add them as friends.'
    },

    noFriendsSearch: {
      title: 'No friends found',
      description: 'No persons were found on this phone number. Try another.'
    },

    friendList: {
      remove: 'Remove',
      add: 'Add'
    }
  }
}