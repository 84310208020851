import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error';
import { setContext } from "@apollo/client/link/context"
import VueApollo from 'vue-apollo'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"

import { getMainDefinition } from 'apollo-utilities'
import { Preferences } from '@capacitor/preferences'
import { usePlatformStore } from '@/store/modules/platform'
import pinaStore from '@/store/index'
import * as Sentry from '@sentry/vue';

async function getAuthorization() {
  const { value: platformToken } = await Preferences.get({ key: 'PLATFORM_TOKEN' })
  const { value: userToken } = await Preferences.get({ key: 'USER_TOKEN' })
  const token = platformToken || userToken
  return token ? `Bearer ${token}` : ''
}

const httpLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_API,
})

const authLink = setContext(async ({ operationName }, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-apollo-operation-name': operationName,
      authorization: await getAuthorization()
    }
  }
})

//@ts-ignore
const httpLinkAuth = authLink.concat(httpLink)


let wsIsConnected = false
export const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_SUBSCRIPTIONS as string,
    /*
    connectionParams: async () => {
      return {
        authorization: await getAuthorization()
      }
    },
    */
    on: {
      connected: (socket) => {
        console.log('wsLink - connected')
        const Platform = usePlatformStore(pinaStore)
        wsIsConnected = true
        Platform.setConnectedToGraphqlApi(true)
      },
      closed: (event) => {
        console.log('wsLink - closed', event)
        const Platform = usePlatformStore(pinaStore)
        wsIsConnected = false
        //! Nogen gang bliver websocket smidt af og connectet med det samme.
        //! Dette fikser det
        setTimeout(() => {
          if (!wsIsConnected) Platform.setConnectedToGraphqlApi(false)
        }, 1000 * 10)
      },
      error: (error) => {
        console.log('wsLink - error', error)
      },
      connecting: () => {
        console.log('wsLink - connecting')
      },
    },
    shouldRetry: () => true,
  })
)

// Error handling link, logs to Sentry
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
		graphQLErrors.forEach((error) => {
			const errorDetails = {
				message: error.message,
				path: error.path,
				locations: error.locations,
				extensions: error.extensions,
			};

      const formattedErrorDetails = JSON.stringify(errorDetails, null, 2);

			Sentry.captureException(
				new Error(`[GraphQL error]: ${JSON.stringify(formattedErrorDetails)}`),
				{
					extra: {
						operationName: operation.operationName,
						variables: operation.variables,
						errorDetails,
					},
				}
			);
		});
	}

  if (networkError) {
    Sentry.captureException(new Error(`[Network error]: ${networkError.message}`), {
      extra: {
        operationName: operation.operationName,
        variables: operation.variables,
      },
    });
  }
});

// Split link for handling subscriptions with WebSocket and other operations with HTTP
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink, // WebSocket link for subscriptions
  httpLinkAuth, // HTTP link with authentication for queries and mutations
)

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]), // Combine error handling with the operation routing logic
  cache: new InMemoryCache(),
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export {
  apolloClient,
  apolloProvider
}
