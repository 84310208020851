import { PaymentCalculatePrice, PaymentCalculatePriceInput, PaymentCalculatePriceQuery, PaymentCalculatePriceQueryVariables, PaymentMethod, PaymentTokenPaymentType, ProductType } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { usePayCouponStore } from './coupon'
import { usePayLoadingStore } from './loading'
import { usePayStore } from './pay'
import { usePayPaymentMethodStore } from './paymentMethod'
import { usePayRestPointsStore } from './restPoints'
import { usePaySubscriptionProductGiftCardStore } from './subscriptionProductGiftCard'
import { usePayUserProductSubscriptionPaymentStore } from './userProductSubscriptionPayment'
import { i18n } from '@/locales/setupI18n'
import { usePayV2PaymentMethodStore } from '../payV2/paymentMethodV2'
import { usePayV2PointsStore } from '../payV2/pointsV2'
import { usePayV2Store } from '../payV2/payV2'
import { usePayV2StripeStore } from '../payV2/stripeV2'

interface PayPriceState {
  priceData: PaymentCalculatePriceQuery['paymentCalculatePrice'] | null

  //* DISCOUNTS
  useDiscounts: boolean

  //* ADDITIONAL SERVICES
  additionalServicesIds: string[]
}

export const usePayPriceStore = defineStore({
  id: 'PayPrice',

  state: (): PayPriceState => ({
    priceData: null,

     //* DISCOUNTS
    useDiscounts: true,

    //* ADDITIONAL SERVICES
    additionalServicesIds: []
  }),

  getters: {
    calculatePriceData(): PaymentCalculatePriceInput | null {
      const stripeV2Store = usePayV2StripeStore()
      const payStore = stripeV2Store.isStripePaymentElementsCompatible ? usePayV2Store() : usePayStore()

      if (!payStore.readyForPayment) return null
      if (!payStore.purchaseData) return null
      if (!payStore.purchaseData.productType) return null

      const productType = payStore.purchaseData.productType

      const payCouponStore = usePayCouponStore()
      const payRestPointsStore = usePayRestPointsStore()
      const paySubscriptionProductGiftCardStore = usePaySubscriptionProductGiftCardStore()
      const payUserProductSubscriptionPaymentStore = usePayUserProductSubscriptionPaymentStore()
      const pointsV2Store = usePayV2PointsStore()
      
      return {
        productType: productType,
        couponSlug: payCouponStore.selectedCouponSlug,
        pointProduct: productType === ProductType.PointProduct ? payStore.purchaseData.pointProductId : null,
        subscriptionProduct: productType === ProductType.SubscriptionProduct ? payStore.purchaseData.subscriptionProductId : null,
        resourceBookingInfo: productType === ProductType.ResourceBooking? {
          resource: payStore.purchaseData?.resourceBooking?.resourceId!,
          startDate: payStore.purchaseData?.resourceBooking?.startDate,
          endDate: payStore.purchaseData?.resourceBooking?.endDate,
          attendees: payStore.purchaseData?.resourceBooking?.attendees,
          internalNotes: payStore.purchaseData?.resourceBooking?.internalNotes,
        } : null,
        workshopBookingInfo: productType === ProductType.Workshop? {
          workshop: payStore.purchaseData?.workshopBooking?.workshopId!,
          userBookingNote: payStore.purchaseData.workshopBooking?.userBookingNote
        } : null,
        restPointsPointAccount: payRestPointsStore.useRestPointsPointAccountId ?? pointsV2Store.restPointAccountId,
        userProductSubscriptionId: payUserProductSubscriptionPaymentStore.useUserProductSubscriptionId,
        additionalServicesIds: this.additionalServicesIds,
        useDiscounts: this.useDiscounts,
        subscriptionProductGiftCardCode: paySubscriptionProductGiftCardStore.selectedGiftCardCode,
        resourceBookingAttendeeBuyOut: payStore.purchaseData.resourceBookingAttendeeBuyOutInput
      }
    },

    priceForDisplay: state => {
      if (!state.priceData) return 0
      const payRestPointsStore = usePayRestPointsStore()
      const restPoints = payRestPointsStore.useRestPointsData ? payRestPointsStore.useRestPointsData?.pointsAmount : 0

      return state.priceData.amountToPay - restPoints
    },

    //* DISCOUNTS
    discountPercentage: state => {
      if (!state.priceData) return 0
      return state.priceData
        ? state.priceData.amountWithoutCoupon !== state.priceData.amountToPay
          ? Math.round((state.priceData.amountWithoutCoupon - state.priceData.amountToPay) / state.priceData.amountWithoutCoupon * 100)
          : 0
        : 0
    },
    
    isUsingDiscounts: state => state.priceData?.amountWithoutCoupon !== state.priceData?.amountToPay,

    showRemoveDiscount: state => state.priceData?.amountWithoutCoupon !== state.priceData?.amountToPay
  },

  actions: {
    async updatePriceAdditionalServices(addedServices: string[]) {
      this.additionalServicesIds = addedServices
    },
    async initCalcuatePrice(source: string, resetRestPoints?: boolean, doPaymentTypes = true) {
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set('initCalcuatePrice', true, {
        source,
        resetRestPoints,
        doPaymentTypes
      })

      if (resetRestPoints) { // TODO: check if there's anything here we need
        const payRestPointsStore = usePayRestPointsStore()
        payRestPointsStore.resetRestPoints()
      }

      await this.calculatePrice({
        source: 'initCalcuatePrice'
      })

      const payCouponStore = usePayCouponStore()
      const payUserProductSubscriptionPaymentStore = usePayUserProductSubscriptionPaymentStore()

      if (await payUserProductSubscriptionPaymentStore.getCanDoUserSubscriptionPayment('initCalcuatePrice')) {
        payLoadingStore.set('initCalcuatePrice', false, {
          returnedByGetCanDoUserSubscriptionPayment: true
        })
        return
      }

      if (await payCouponStore.initCoupons()){
        payLoadingStore.set('initCalcuatePrice', false, {
          returnedByInitCoupons: true
        })
        return
      }

      if (doPaymentTypes) {
        const stripeV2Store = usePayV2StripeStore()
        if (stripeV2Store.isStripePaymentElementsCompatible) {
          const paymentMethodStore = usePayV2PaymentMethodStore()
          await paymentMethodStore.getAllowedPaymentTypes('initCalculatePrice')
        } else {
          const payPaymentMethodStore = usePayPaymentMethodStore()
          await payPaymentMethodStore.getAllowedPaymentTypes('initCalcuatePrice')

        }
      }

      payLoadingStore.set('initCalcuatePrice', false)
    },

    async calculatePrice(data: { source: string }) {
      if (!this.calculatePriceData) throw new Error(i18n.t('common.staticTemp.errorMessageCalcPrice') as string)
      
      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set(`calculatePrice`, true, {
        ...data,
        calculatePriceData: this.calculatePriceData
      })

      const { data: calculatedPrice } = await apolloClient.query<PaymentCalculatePriceQuery, PaymentCalculatePriceQueryVariables>({
        query: PaymentCalculatePrice,
        variables: { data: {
          ...this.calculatePriceData,
        }},
        fetchPolicy: 'network-only'
      })
      
      payLoadingStore.set(`calculatePrice`, false, calculatedPrice.paymentCalculatePrice)

      this.priceData = calculatedPrice.paymentCalculatePrice
    },

    resetPrice() {
      this.priceData = null
      this.useDiscounts = true
    },


    //* DISCOUTNS
    async removeDiscounts() {
      const payRestPointsStore = usePayRestPointsStore()
      const payPaymentMethodStore = usePayPaymentMethodStore()

      const payLoadingStore = usePayLoadingStore()
      payLoadingStore.set(`calculatePrice`, true)

      payRestPointsStore.resetRestPoints(true)
      
      await this.setUseDiscounts(false)
      await payPaymentMethodStore.setPaymentType(PaymentTokenPaymentType.Points, 'removeDiscounts')
      payLoadingStore.set(`calculatePrice`, false)
    },


    async setUseDiscounts(newState: boolean) {
      this.useDiscounts = newState
      await this.initCalcuatePrice('setAllowUseDiscounts')
    }
  }
})
