import defaultRoutes, { DefaultRoutesPath } from "@/config/defaultRoutes";
import {
	Currency,
	GetUserAuth,
	GetUserAuthQuery,
	GetUserInformation,
	SupportedCountries,
	UserRole,
} from "@/graphql";
import { defineStore } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { apolloClient } from "@/plugins/apollo";
import { changeCurrency, i18n } from "@/locales/setupI18n";
import { useAuthStore } from "./auth";
import { useLocationStore } from "./location";
import { useRanglistenStore } from "./ranglisten";
export interface UserState {
	user: {
		id: string;
		firstName: string;
		lastName: string;
		role: UserRole;
		companyId?: string;
		country: SupportedCountries;
		ecomAvailable: boolean;
		userPlatformConnectionId: string;
		hasAdminRole: boolean;
		currency: Currency;
	} | null;
	defaultRoutes: DefaultRoutesPath | null;
	opndId: string | null;
}

export const useUserStore = defineStore({
	id: "User",

	state: (): UserState => ({
		user: null,
		defaultRoutes: null,
		opndId: null,
	}),

	getters: {
		userCurrentCompanyId: (state) => {
			if (!state.user) return undefined;
			return state.user.companyId;
		},
		currentUser: (state) => state.user,
	},

	actions: {
		setUserDefaultRoutes() {
			if (!this.user)
				throw new Error(
					i18n.t("common.staticTemp.user.errorMessageUserNotSet") as string
				);
			const routes = defaultRoutes[this.user!.role];
			this.defaultRoutes = routes;
		},

		setOpndId(id: string) {
			this.opndId = id;
		},

		clearOpndId() {
			this.opndId = null;
		},

		async getUser() {
			try {
				const { value: platformToken } = await Preferences.get({
					key: "PLATFORM_TOKEN",
				});
				if (!platformToken) {
					throw new Error(
						i18n.t("common.staticTemp.user.errorMessagePlatformToken") as string
					);
				}

				const getUser = await apolloClient.query({
					query: GetUserAuth,
					fetchPolicy: "network-only",
				});
				const data = (getUser.data as GetUserAuthQuery).me;

				const userData: UserState["user"] = {
					id: data.user.id,
					firstName: data.user.firstName ?? "",
					lastName: data.user.lastName ?? "",
					role: data.role,
					companyId: data.company ? data.company.id : undefined,
					country: data.country,
					ecomAvailable: data.company?.usingEcom ?? false,
					userPlatformConnectionId: data.userPlatformConnection.id,
					hasAdminRole: data.hasAdminRole,
					currency: data.currency!,
				};

				if (data.currency) changeCurrency(data.currency);

				this.user = userData;
			} catch (error) {
				const Auth = useAuthStore();
				Auth.signUserOut();
				throw new Error(error as any);
			}
		},

		async getUserInformation() {
			const response = await apolloClient.query({
				query: GetUserInformation,
			});
			return response.data.me.user;
		},

		async initUser() {
			const Auth = useAuthStore();
			const Location = useLocationStore();
			const RanglistenStore = useRanglistenStore();

			try {
				await this.getUser();
				await Auth.getUserPlatformConnections();
				await Location.initLocation();
				await RanglistenStore.getSignUpLink();
				this.setUserDefaultRoutes();
				if (!this.user)
					throw new Error(i18n.t("common.staticTemp.user.errorCode") as string);
				return this.user;
			} catch (error) {
				Auth.signUserOut();
			}
		},

		destroyUser() {
			this.user = null;
			this.defaultRoutes = null;
		},
	},
});
