export default {
  workshops: {
    title: 'Events',
    description: 'Here you can sign up and read more about events at our location.',
    viewButton: 'See our events'
  },

  points: {
    title: 'Points',
    description: 'When you buy points, they are added to your account and you can use them when you book a course. 1 kr = 1st point. You can use your points at all court types and at all times.',

    youHave: 'You have',
    points: 'points',
    at: 'on your account at',

    pointProductCard: {
      point: 'Points'
    }
  },

  userProductSubscription: {
    title: 'Memberships',
    activeTitle: 'Active memberships',
    pendingTitle: 'Pending memberships',
    notPossible: 'No possibility of membership at {0} at the moment.',
    isActive: 'You are registered for this membership',
    firstPayment: 'The first payment will be deducted on it',
    isToCancele: 'This membership expires on',
    pendingStatus: 'This membership is in pending status and requires action',
    activationWarning: 'Please note that the membership needs to be confirmed by our payment provider after purchase. It might therefor not be activated immediately.',

    upgrade: {
      title: 'Do you want to change your current membership to another one?',
      text: "Then just click on the membership you want and sign up. Then we'll cancel your existing membership."
    },

    subscriptionProduct: {
      perMonth: 'per month',
      perYear: 'per year',
      paidUpFront: 'paid up front per {0} month',
      signUpFee: 'Sign up fee',
      couponOnOtherPayments: 'Discount on other bookings',
      paymentDiscountType: 'Discount on share',
      prHour: 'per hour',
      maxActiveBookings: {
        title: 'Maximum number of bookings with membership',
        unlimited: 'Infinite'
      }
    }
  }
}