<template lang="pug">
b-overlay(
  :show="$apollo.queries.userData.loading || updateUserLoading"
  variant="transparent"
  spinner-variant="primary")

  validation-observer(ref='observer' v-slot='{ invalid }')
    b-form(@submit.prevent='updateUserInfo' novalidate)

      b-row
        b-col
          b-form-group(:label="$t('components.shared.updateUser.firstNameLabel')")
            validation-provider(name='first-name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='updateUserData.firstName'
                type='text'
                :placeholder="$t('components.shared.updateUser.firstNamePlaceHolder')"
                name='first-name')
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

        b-col
          b-form-group(:label="$t('components.shared.updateUser.lastNameLabel')")
            validation-provider(name='last-name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='updateUserData.lastName'
                type='text'
                :placeholder="$t('components.shared.updateUser.lastNamePlaceHolder')"
                name='last-name')
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

      b-form-group(:label="$t('components.shared.updateUser.countryLabel')")
        v-select(
          :options="getCountryOptions()"
          value="value"
          :clearable="false"
          :reduce="x => x.value"
          v-model="updateUserData.country")

      b-form-group(:label="$t('components.shared.updateUser.phoneLabel')")
        validation-provider(name='phoneNumber' rules='required|integer' v-slot='{ errors, untouched }')
          b-input-group
            template(#prepend)
              b-dropdown(:text="phoneCountryCodeText" variant="outline-primary")
                b-dropdown-item(
                  v-for="(item, index) in phoneCountryCodes"
                  :key="index"
                  @click="setPhoneCountryCode(item.code)"
                ) {{ item.flag }} {{ item.code }}
            b-form-input(
              :state='untouched === true ? null : errors.length == 0 ? true : false'
              v-model='updateUserData.phoneNumberWithoutCountryCode'
              :placeholder="$t('components.shared.updateUser.phonePlaceHolder')"
              name='phoneNumber')
            b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

      b-form-group(:label="$t('components.shared.updateUser.emailLabel')")
        validation-provider(name='email' rules='required' v-slot='{ errors, untouched }')
          b-form-input(
            :state='untouched === true ? null : errors.length == 0 ? true : false'
            v-model='updateUserData.email'
            :placeholder="$t('components.shared.updateUser.emailPlaceHolder')"
            name='email')
          b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

      b-form-group(:label="$t('input.streetName.label')")
        validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
          b-form-input(
            :state='untouched === true ? null : errors.length == 0 ? true : false'
            v-model='updateUserData.streetName'
            type='text' 
            :placeholder="$t('components.shared.updateUser.streetNamePlaceHolder')")
          b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

      b-row
        b-col
          b-form-group(:label="$t('input.zipCode.label')")
            validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='updateUserData.zipCode'
                type='text' 
                :placeholder="$t('input.zipCode.placeholder')")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

        b-col
          b-form-group(:label="$t('input.city.label')")
            validation-provider(name='name' rules='required' v-slot='{ errors, untouched }')
              b-form-input(
                :state='untouched === true ? null : errors.length == 0 ? true : false'
                v-model='updateUserData.city'
                type='text' 
                :placeholder="$t('input.city.placeholder')")
              b-form-invalid-feedback(:state='errors.length == 0') {{errors[0]}}

      b-button.mt-4(
        variant="primary"
        block
        @click="updateUserInfo()"
        size="lg"
        :disabled="invalid || updateUserLoading")
        span(v-if="updateUserLoading")
          b-spinner.button-loading(small type="grow")
          span {{ $t('components.shared.updateUser.updateButtonLoading') }}
        span(v-else)  {{ $t('components.shared.updateUser.updateButton') }}


</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserUpdate, UserUpdateMutationVariables, GetUpdateUserInfoQuery, GetUpdateUserInfo, GetUpdateUserInfoQueryVariables, SupportedCountries } from '@/graphql'
import vSelect from 'vue-select'
import { useShopStore } from '@/store/modules/ecom'
import { sendSegmentData, SegmentTypeOfEvent } from '@/segment/segmentData'
import { getCountryOptions } from '@/utils/getCountryOptions'
import { getPhoneCountryCodes } from '@/utils/getPhoneCountryCodes'

@Component({
  components: {
    vSelect
  },
  apollo: {
    userData: {
      query: GetUpdateUserInfo,
      update(data: GetUpdateUserInfoQuery) {
        return data.user
      },
      variables(): GetUpdateUserInfoQueryVariables {
        return {
          id: this.userId
        }
      },
    },
  },
  methods: {
    getCountryOptions
  }
})
export default class CompanyUsersUserView extends Vue {

  shopStore = useShopStore()

  @Prop({ required: false }) private userId!: string

  phoneCountryCodes = getPhoneCountryCodes()
  phoneCountryCodeText: string | null = null

  userData: GetUpdateUserInfoQuery['user'] = {
    id: '',
    firstName: '',
    lastName: '',
    zipCode: null,
    city: null,
    streetName: null,
    phoneCountryCode: '',
    phoneNumberWithoutCountryCode: '',
    email: '',
    country: SupportedCountries['Dk']
  }

  setPhoneCountryCode(code: string): void {
    this.updateUserData.phoneCountryCode = code
    this.phoneCountryCodeText = code
  }

  get updateUserData() {
    return { ...this.userData }
  }

  updated() {
    this.phoneCountryCodeText = this.userData.phoneCountryCode!
    this.shopStore.clearShoppingBag()
  }

  updateUserInfoFormDisabled = false
  updateUserLoading = false
  async updateUserInfo() {
    this.updateUserLoading = true

    const variables: UserUpdateMutationVariables = {
      user: this.userId,
      data: {
        firstName: this.updateUserData.firstName,
        lastName: this.updateUserData.lastName,
        zipCode: this.updateUserData.zipCode,
        phoneCountryCode: this.updateUserData.phoneCountryCode,
        phoneNumber: this.updateUserData.phoneNumberWithoutCountryCode,
        streetName: this.updateUserData.streetName,
        email: this.updateUserData.email,
        city: this.updateUserData.city,
        country: this.updateUserData.country
      }
    }

    const updateUser = await this.$apollo.mutate({
      mutation: UserUpdate,
      variables,
      errorPolicy: 'all'
    })

    if (updateUser.errors) {
      this.$bvToast.toast(updateUser.errors[0].message, {
        title: this.$t('common.error') as string,
        variant: 'danger'
      })
      this.updateUserLoading = false
      return
    }

    this.updateUserLoading = false

    this.$bvToast.toast(this.$t('components.shared.updateUser.success') as string, {
      title: this.$t('common.bookli') as string,
      variant: 'success'
    })

    this.$apollo.queries.userData.refetch()

    this.$emit('done')

    const dataForSegment = {
			first_name: this.updateUserData.firstName,
			last_name: this.updateUserData.lastName,
			email: this.updateUserData.email,
			city: this.updateUserData.city,
			country: this.updateUserData.country,
      phone_number: this.updateUserData.phoneNumberWithoutCountryCode,
			booking_system: 'LeDap',
			zip_code: this.updateUserData.zipCode,
		}

		sendSegmentData(SegmentTypeOfEvent.IdentifyUser, dataForSegment)
  }

}
</script>

<style lang="sass">
@import "vue-select/src/scss/vue-select.scss"
.vs__dropdown-toggle
  border-radius: 16px
  padding: 5px 6px 7px 6px
  border: 1px solid #D2DDEC

.input-group
  .form-control
   border-radius: 0 16px 16px 0 !important
</style>