<template lang="pug">
b-modal.bottom-modal(
  v-model="addStripeSourceVisible"
  :backdrop="true"
  hide-header
  hide-footer
  modal-class="bottom-modal"
  body-class="p-0")
  .buttom-sidebar-close-wrapper.cursor-pointer(@click="addStripeSourceVisible = false")
    .fe.fe-arrow-left.text-muted.mr-2
    small.text-muted {{ $t('common.cancel') }}

  .bottom-modal-content
    b-overlay(
      :show="payLoadingStore.loading"
      variant="white"
      spinner-variant="primary")
      .px-4.py-4
        h3 {{ $t('components.app.addStripeSource.title') }}
        p {{ $t('components.app.addStripeSource.description') }}

        div(v-if="payStripeStore.useStripeWeb")
          stripe-elements(
            :stripe-key="stripeKey"
            :elements-options="elementsOptions"
            ref="elms"
            #default="{ elements }")
            stripe-element(
              type="card"
              ref="card"
              :elements="elements"
              @change="complete = $event.complete")

          b-button.mt-3(
            block
            size="lg"
            :disabled="!complete"
            @click="addSourceWeb()"
            variant="primary") {{ $t('components.app.addStripeSource.addCard') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {  } from '@/graphql'
//@ts-ignore
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { usePayStore } from '@/store/modules/pay/pay'
import { usePayStripeStore } from '@/store/modules/pay/stripe'
import { usePayLoadingStore } from '@/store/modules/pay/loading'

@Component({
  components: {
    StripeElements,
    StripeElement
  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  payStore = usePayStore()
  payLoadingStore = usePayLoadingStore()
  payStripeStore = usePayStripeStore()

  addStripeSourceVisible = false

  complete = false
  stripeKey = process.env.STRIPE_KEY
  instanceOptions = {
    // https://stripe.com/docs/js/initializing#init_stripe_js-options
  }

  elementsOptions = {
    // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
  }

  cardOptions = {
    // https://stripe.com/docs/stripe.js#element-options
  }

  async mounted() {
    await this.payStripeStore.initStripe()
  }

  async show() {
    this.addStripeSourceVisible = true

    if (this.payStripeStore.useStripeWeb) {

    }
    else {
      await this.payStripeStore.createSavedSource()
      this.addStripeSourceVisible = false
    }
  }

  async addSourceWeb() {
    const groupComponent = this.$refs.elms
    const cardComponent = this.$refs.card
    //@ts-ignore
    const card = cardComponent.stripeElement
    //@ts-ignore
    const stripe = groupComponent.instance

    await this.payStripeStore.createSavedSource(stripe, card)
    this.addStripeSourceVisible = false
  }
}
</script>

<style lang="sass" scoped>

</style>