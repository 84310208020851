export default {
  title: 'Refund / cancel payment',
  description: 'What do you want to do with this payment? Fill in the checkmarks underneath to choose what to do with the payment and the booking.',

  isCreatedWith: 'The payment is created with',
  andValueIs: 'and is',
  point: 'points',

  refundPaymentCheckbox: 'Refund payment',
  refundAs: 'Refund as',

  canceleProductButton: 'Cancel booking',

  submitLoadingButton: 'Refunding......',
  submitButton: 'Confirm',

  close: 'Close',

  additionalService: {
    description: 'This booking also includes an additional service. If you wish to only cancel and/or partially refund the additional service, you can do so here.',
    descriptionAdditionalInfo: 'Please note that if you only refund the additional service now, you can not later on refund the full booking.',
    cancelService: 'Cancel only additional service',
    refundService: 'Refund only additional service',
    valueService: 'The value of the additional service is',
    refundedAlready: 'The additional service is already refunded or in another status',
    canceledAlready: 'The additional service is already canceled',
  },

  refundTypeOptions: {
    money: 'Money',
    point: 'Point',
    asPaymentIsCreated: 'The same way the paymet is created',
    markAsRefunded: 'Mark as refunded'
  },

  paymentTooltipMessage: {
    noPayment: 'There is no payment connected',
    notPaid: 'Payment is already refunded or in another status'
  },

  bookingCancelTooltipMessage: {
    hasBeenDone: 'Booking is already done',
    isCancled: 'Booking is canceled',
    hasToBeCancledByMasterPayment: 'Payment is part of another payment. You have to cancel with the master payment'
  },

  successToast: 'Your action is completed'
}