export default {
  errorMessagePriceData: 'getCanDoUserSubscriptionPayment - missing priceData',
  errorMessageNoSubProdSel: "subscriptionProductGiftCardCodeIsValid - No subscription product selected",
  errorMessageCalcPrice: 'calculatePrice - missing calculatePriceData',
  errorMessageConvert: 'Cannot convert undefined or null to object',

  privat: "Private",
  pointslabel: "points",
  paypal: 'PayPal',
  stripe: 'Stripe',
  type: 'Type',
  currency: 'DKK',
  errorNoID: 'There is no ID sent with the resource booking...',
  deletedUser: 'The user has been deleted',
  mobilePay: 'MobilePay invoice',
  stripeCode: 'xxxx xxxx xxxx',
  courtNumber: 'Court number',
  missingCompanyId: 'Missing company ID',
  login: 'Log in',
  createUser: 'Create user',
  newUsers: 'New users',
  closeWindow: 'Close window',
  integration: 'Integration',
  connected: 'Connected',
  degree: 'C°',
  couponDiscountInfo: 'Discount only working for specific court types',
  bookCourt: 'Book a court',
  errorMessageSelectedTime: 'calculatePriceFunction - missing selectedTime',
  errorMessageSelectedResource: 'calculatePriceFunction - missing selectedResource',
  telephoneNr: 'Phone number',
  ownerName: 'Owner name',
  goneWrongError: "An error occurred",
  masterClipError: "Need to be master",
  showTheWay: " - Show the way: ",
  closeAll: "Close all",
  supportTicket: "Create support ticket",
  selectLocation: "Select your location",
  link: "link",
  submit: "Submit",
  bannerLink: "Banner link URL",

  workshopCalendar: {
    titleEvent: '🎾 Padel event booking',
    title: 'Title',
    when: 'WHEN',
    time: 'TIME',
    where: 'WHERE',
    court: 'COURT',
    to: 'to',
    backToLedap: 'Back to LeDap',
    padelBooking: '🎾 Padel booking'
  },

  maintenance: {
    title: 'Maintenance',
    maintenance: 'Under maintenance / update',
    info: 'We are constantly working to improve the system and bring new features to you. Therefore, the site is currently down for a short period to update the systems.'
  },

  goToUser: {
    title: 'Go to user',
    notAccess: 'You do not have access to any pages associated with this user',
    errorMessageUser: 'Could not find the user',
  },

  companyStats: {
    nrOfUsers: 'Number of users',
    avgNewUsers: 'Avg. new users per day',
    revenue: 'Revenue',
    avgRevenue: 'Avg. annual revenue per customer',
    bookings: 'Bookings',
    usersWithFutureBookings: 'Users WITH future bookings',
    percent: 'Percent',
    avgDaysToNextBooking: 'Avg. days to next booking',
    usersWithoutBooking: 'Users WITHOUT future bookings',
    avgDaysLastBooking: 'Avg. days since last booking',
    usersNoBooking: 'Users WITHOUT created bookings',
    groupedRevenue: 'Grouped by revenue in period',
    avgRevenueUser: 'Annual revenue per user',
    bookings24Hours: 'Booking percentage in 24 hours',
    revenueCourt: 'Revenue per court',
    avgBookings24h: 'Avg. booking percentage in 24 hours',
    avgCancellationRate: 'Avg. cancellation rate',
    avgHoursBooking: 'Avg. hours per booking',
    avgRevenueHour: 'Avg. revenue per booked hour',
    prCourt: 'Per court',
    avgHoursDay: 'Avg. hours per day',
    avgRevenueDay: 'Avg. revenue per day ',
    newUsersWithBookings: 'New users with at least 1 booking each week',
    pointSystem: 'Points in system',
    perPaymentMethod: 'Per payment method',
    rawData: 'Raw data'
  },

  bookliVideo: {
    wentWrong: 'Something went wrong...',
    wrongInfo: 'Either we could not find this video, or you do not have access to it. Only participants in the booking are allowed to view the video.',
    help: 'Help',
    goToLedap: 'Go to LeDap',
    exportVideo: "How to export a video",
  },

  bookliDevice: {
    errorOccured: 'An error occurred...',
    errorMessageToken: 'A token is missing'
  },

  kpi:{
    title: 'KPI Export',
    period: 'Period',
    periodType: 'Period type',
    sendToEmail: 'Send to email',
    create: 'Create',
    created: 'The report was created',
    kpiReport: 'KPI Report'
  },

  scenarios: {
    statistic: 'Statistics',
    scenarios: 'Scenarios',
    info: 'Various basic information',
    revenueBookedTime: 'Revenue per booked hour',
    annualRevenueUser: 'Annual revenue per user',
    hrsWeekUser: 'Hours per week per user',
    increaseUsersDay: 'Increase the number of new users per day',
    percentIncrease: 'Percentage increase',
    currentAvgDay: 'of current average per day -',
    annualValue: 'Annual value',
    average: 'average right now',
    extraNewUsers: 'Extra new users per day',
    annualRevenue: 'Annual revenue',
    changeUsers: 'Change the users who have never created a booking before',
    percetageOfUsers: 'Work with a percentage of users',
    currentUsersCategory: 'current users in this category:',
    avgValBookedHr: 'average value per booked hour',
    getUsersToPlay: 'Get users without a future booking to play x number of times more per week',
    avgHrsWeek: 'Avg. hours per week',
    avgHrsUsersWithBooking: 'average right now for users with at least 1 booking',
    desiredPercentage: 'Increase the daily booking percentage to the desired percentage (24h)',
    percentage: 'Percentage',
    currentPercentage: 'current percentage:',
    activeUsers: 'Active users',
    currentNrOfUsers: 'current number of users:',
    nrOfExtraCourts: 'Number of extra courts',
    currentNr: 'current number',
    revenuePercentagePoint: 'Revenue per percentage point is',
    totalRevenueDay: 'Total revenue per day',
    gainDay: 'Gain per day',
    additionalHours: 'Estimated additional hours per day',
    avgHrsUserWeek: 'Avg. hours per user per week',
    totalRevenue: 'Total annual revenue',
    gainAnnually: 'Gain annually',
    platformRevenue: 'Platform revenue',
    annually: 'Annually:',
    monthly: 'Monthly:',
    now: 'now',
    bookingRate: 'at a booking rate of',
    and: 'and',
    revenueCourtDay: 'in revenue per court per day (total per day:',
    turnover: '). There is a turnover of',
    percentEquals: 'per booked hour. One percent equals',
    time: 'hour'
  },

  points: {
    statistics: 'Statistics',
    point: 'Points',
    outstandingPoints: 'Outstanding points',
    ratio: 'Point ratio',
    errorMessagePointId: "You have to send pointProductId",
    pointAccount: "Point account",
    getExcelFile: "Get point account excel file",
    getAccountNumbers: "Get point account numbers",
    getAccountNumbersDone: "Get point account numbers done",
    toDate: "To date",
    result: "Result",
    dateRange: "Date range",
    update: "Update",
    updatePointTransaction: "Update point transaction",
    value: "Value",
    lineUpdated: "The line is updated",
    pointAmountOtOTrue: "pointAmountOtOTrue",
    pointAmountOtOFalse: "pointAmountOtOFalse",
    pointsAmount: "pointsAmount",
    pointAmountOtOFalseRatio: "pointAmountOtOFalseRatio",
    pointAmountOtOMixRatio: "pointAmountOtOMixRatio",
  },

  companyCreated: {
    fromCompanyStartup: 'From company start',
    avgRevenueLaneDay: 'Avg. revenue per lane per day',
    avgRevenueUserDay: 'Avg. revenue per user per day',
    newUsers: 'Number of new users',
  },

  platformInvoice: {
    title: 'Platform invoice',
    description: "Generate a Proforma invoice for the franchise by clicking on the button below. Choose dates and then click on 'Get invoice'",
    createInvoice: 'Create a proforma invoice'
  },

  index: {
    overview: 'Overview',
    settings: 'Settings',
    start: 'Start',
    general: 'General',
    admin: 'Administrators',
    companies: 'Companies',
    memberships: 'Memberships',
    integrations: 'Integrations',
    payment: 'Payment',
    agreements: 'Agreement documents',
    invoice: 'Platform invoice',

    settingPages:{
      company: 'Company',
      discountCodes: 'Discount codes',
      points: 'Points',
      dinero: 'Dinero',
      description: 'Info and settings for your company'
    }
  },

  franchise: {
    createAsFranchise: "Create as franchise",
    creatingInvitation: "Creating invitation...",
    createInvitation: "Create invitation",
    sendInvitation: "Send create company invitation",
    invitationSentTo: "An invitation has been sent to",
    newCompany: "New company",
    companyInvitation: "Company invitations"
  },

  companies: {
    description: "Here, you can easily add a new company to your franchise. Simply click on the 'Add new company' button and fill in the required email address. An invitation will be sent to the email address provided, allowing the recipient to complete the company creation process.",
    addNewCompany: 'Add new company',
    email: 'Email',
    companyInvitations: 'Company invitations',
    createsLabel: 'Created date',
    createNewCompany: 'Create new company',
    newCompanyInput: 'You are now creating a new company invitation. Fill in the fields below.',
    placeholderEmail: 'name@company.com',
    creatingCompanyInvitation: 'Creating company invitation...',
    createCompanyInvitation: 'Create company invitation',
    invitationSent: 'An invitation has been sent to',
    newCompany: 'New company',
    actionOne: 'Action 1',
    deleteAction: 'Delete action',
    errorMessageNoId: "No id was sent...",
  },

  administators: {
    admin: 'Administrators',
    description: "This page allows you to manage administrators for the franchise. Here, you can invite new administrators by providing their details, or remove existing ones if necessary.",
    addAdmin: 'Add new administrator',
    adminInvitation: 'Administrator invitations',
    createFranchiseAdmin: 'Create franchise admin',
    creatingAdminInput: 'You are now creating an administrator. Fill in the fields below.',
    creatingAdmin: 'Creating administrator...',
    createAdmin: 'Create administrator',
    createdInfo: 'An admin with email has been created',
    profileUpdated: "Your profile has been updated",
    noAdminInvites: "No administrator invites sent",
    noAdmins: "No administrators added",
  },

  users: {
    totalUsers: 'Total users',
    newToday: 'New today',
    created: 'Created',
    phoneNr: 'Mobile nr.',
    user: 'User',
    numbers: '2231',
    numbers2: '25',
    search: 'Search by first name, last name, email and phone number',
    searchPhoneNr: 'Search by phone number'
  },
  user: {
    createdUser: 'This user has been deleted',
    paymentSum: 'Payment sum',
    totalBookings: 'Total bookings',
    phoneNr: 'Phone number',
    loginMethod: 'Login method',
    accessControlCode: 'Access control code',
    resetPassword: 'Send access code reset email',
    deleteUser: 'Delete user',
    showGroups: 'Show group',
    usergroups: 'User groups',
    noGroups: 'No groups',
    notRegisteredGroups: 'This user is not registered in any groups.',
    membership: 'Memberships',
    noMemberships: 'No memberships',
    userNoMember: 'This user is not registered in any memberships.',
    bookings: 'Bookings',
    payments: 'Payments',
    updatePayments: 'Update user information',
    userRemoved: 'The user has been removed',
    deleteUserInfo: 'Are you sure about this? When you delete a user, the person will be deleted across the platform and will not be able to log in again. This feature is not always functional - it will be improved in the future',
    deletingUser: 'Deleting user',
    cancel: 'Cancel',
    couldntDelete: 'The user could not be deleted',
    showPointAccount: "Show point account",
    fullLifespan: "Full lifespan",
    lastTwoMonths: "Last 2 months",
    lastFourMonths: "Last 4 months",
    errorMessagePlatformToken: 'Platform token is missing',
    errorMessageUserNotSet: 'User is not set',
    errorCode: 'Error 011',
  },

  createWorkshop: {
    time: 'at',
    toTime: 'to',
    court: 'Courts',
    month: "Month",
    week: "Week",
  },

  bookingSlots:{
    slots: 'Booking slots',
    slotTarget: 'Slot target',
    selectType: 'Select a type',
    date: 'Date',
  },


  reports: {
    title: 'Reports',
    pointsAccount: 'Point accounts report',
    paymentsExport: 'Payments export',
    profomaInvoice: 'Platform proforma invoice',
    toDate: 'To date',
    date: 'Date',
    createdDateRange: 'Created at date range',
    created: 'The report was created'
  },

  messages: {
    resource: 'resource',
    accessCode: 'accessCode',
    hrsBeforeBooking: 'hoursBeforeBooking',
    companyName: 'companyName',
    websiteUrl: 'websiteUrl',
    email: 'email',
    userFirstName: 'userFirstName',
    startTime: 'startTime',
    endTime: 'endTime',
    resourceName: 'resourceName',
  },

  giftCard: {
    notUsed: 'Not used',
    expire: 'Expiration date',
    giftVouchers: 'Gift vouchers'
  },

  prodSubscription: {
    status: 'Status',
    platform: 'Platform',
    membership: 'Membership',
    downloadCSV: 'Download CSV',
    all: 'All',
    admin: 'Admin',
    active: 'Active',
    toActive: 'Soon Active',
    toCancel: 'Soon Canceled',
    canceled: 'Canceled',
    linkCSV: 'Link to CSV',
    updateInfo: "Update information",
  },

  signIn: {
    facebookLogin: 'Log in with Facebook',
    credentials: 'Or continue with credentials',
    signIn: "Sign in",
    signingIn: "Signing in...",
    goneWrong: "Something went wrong.",
  },


  technician: {
    title: 'Technicians',
    technician: 'Technician',
    description: "Create and manage technicians within the app. To add a new technician, simply click the button below, enter their email address, and an invitation will be sent for them to set up their account.",
    addNewTech: 'Add new technician',
    creatingNewTech: 'You are now creating a new technician. Fill out the fields below.',
    creatingTech: 'Creating technician...',
    createTech: 'Create technician',
    createdTech: 'The technician has been created',
    createdDate: 'Created on 1',
  },

  dinero: {
    title: 'Dinero',
    info: 'With the addition of Dinero, you can have all your payments recorded. That way, you can avoid a lot of work, and everything becomes more secure.',
    showIntegration: 'Show Dinero integration',
    description: "With the addition of Dinero, you can have all your payments recorded. That way, you can avoid a lot of work, and everything becomes more secure.",
    addDineroConnection: 'Add Dinero connection',
    createDineroConnection: 'Create Dinero connection',
    nowConnecting: 'You are now connecting with Dinero. Fill out the fields below.',
    apiKey: 'API key',
    apiPlaceholder: '123###',
    organisationId: 'Organization ID',
    createConnection: 'Create connection',
    creatingConnection: 'Creating connection...',
    connectionCreated: 'The connection to Dinero has been created',
  },
  
  economic: {
    title: 'E-conomic',
    info: 'With the e-conomic integration, LeDap can handle its own bookkeeping.',
    showIntegration: 'Show e-conomic integration',
    microsoft: 'Microsoft Business Central',
    writeToHans: 'To access this, you need to contact Hans Kristoffer - 42962252 or info@ledap.app',
  },

  mobilePayInvoice: {
    title: 'MobilePay',
    description: 'The majority of your users are familiar with MobilePay, and for many, it is their preferred method of payment. Add MobilePay below and let your users pay even more easily.',
    showIntegration: 'Show MobilePay integration',
    connectMobilePay: 'Connect MobilePay',
    openMobilePay: 'Open MobilePay Dashboard',
    errorMessageNotAccepted: 'MobilePay not accepted',
    errorMessageDidntAccept: 'startPaymentMobilePay - did not accept'
  },

  msBusiness: {
    description: 'Through integration with Microsoft Business Central, LeDap can automatically handle all payments.',
    showIntegration: 'Show MS Business integration'
  },

  payPalIntegration: {
    showIntegration: 'View PayPal integration',
    connected: 'Connected',
    connect: 'Connect PayPal',
    open: 'Open PayPal',
    add: 'Add PayPal',
    webhook: 'Webhook URL (select: all events)',
    url: 'https://webhooks.bookli.app/paypal-callbacks',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    connectionCreated: 'PayPal connection is created',
    errorMessageNoPaypal: "initPayment - missing PayPal",
    errorMessageNoPaypalBtns: 'initPayment - missing PayPal.Buttons',
    errorMessagePaymentToken: 'initPayment - missing paymentToken',
    errorMessageInvoiceId: 'initPayment - missing PayPalInvoiceId'
  },

  admin: {
    adminCreated: 'Admin is created',
  },

  payment: {
    paid: 'Paid',
    pending: 'Pending',
    failed: 'Failed',
    refunded: 'Refunded',
  },

  scriptable: {
    title: 'Scriptable',
    description: "This is a piece of code you can use on an iPhone to view statistics about your company directly on your home screen.",
    showIntegration: "Show iPhone integration",
    iphoneIntegration: "iPhone Integration",
    link: "Link to download Scriptable",
    copy: "Copy code"
  },

  stripeIntegration: {
    title: 'Stripe',
    description: "Stripe is the platform for handling card payments. Once you've created an account on Stripe through the button below, you can receive all forms of card payments from your customers.",
    showIntegration: "Show Stripe integration",
    addConnection: "Add Stripe connection",
    openDashboard: "Open Stripe Dashboard",
    showReports: "Show Stripe Reports",
    reports: "Stripe Reports",
    overview: "Overview",
    createReport: "Create report",
    status: "Status",
    type: "Type",
    generate: "Generate",
    balanceSummary: "Balance Summary",
    balanceChangeFromActivitySummary: "Balance Change From Activity Summary",
    payoutsSummary: "Payouts Summary",
    reportCreated: 'Report is created',
    bill: "Stripe Bill",
  },

  wannasport: {
    title: 'Wannasport',
    description: "By adding Wannasport, you can offer your courts on their platform.",
    showIntegration: "Show Wannasport integration",
    createConnection: "Create Wannasport connection",
    nrOfPoints: "Number of points",
    showPointAccount: "Show point account",
    slots: "Slots",
    changeSlot: "Change slots",
    daysList: "[{'days':['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],'times':[['05:00','23:00']]}]",
    connectionCreated: "The connection has been created",
    slotsUpdated:"Slots have been updated"
  },

  userActivities: {
    activity: 'Activity',
    comingSoon: "Coming soon...",
    userCreated: "User created",
    choseDate: "Date selection"
  },

  info: {
    joinCustomerClub: "Go to our customer club",
    description: "Through our customer club, you can get special discounts, receive information about upcoming events, and access a lot of information about our center.",
    return: "Back to booking"
  },

  localeType: {
    danishDk: "Danish DK",
    englishGb: "English GB",
    germanDe: "German DE",
  },

  openDoor: {
    info: "Hold your phone near the scanner at the door. Once we've registered the door, it will be unlocked.",
    doorOpen: "The door is now unlocked, and you can open it.",
    canceledOrNoAccess: "Either you canceled the process, or you do not have access to this door...",
    openDoor: "Door Opening",
    checking: "Checking...",
    openTenSec: "The door is now open for the next 10 seconds.",
    cannotOpen: "It seems you cannot open this door...",
  },

  franchiseSettings: {
    company: "Company",
    discount: 'Discount Codes',
    points: 'Points',
    dinero: 'Dinero',
    description: 'Info and settings for your company'
  },

  tableFields: {
    slug: 'Slug',
    name: 'Name',
    phonenr: 'Phone Number',
    locations: 'Locations',
    id: 'ID',
    createdAt: 'Created At',
  },

  locations: {
    noLocations: 'No locations added to this company yet',
  },

  accessCode: {
    errorMessageNoId: "No ID provided...",
    never: "Never"
  },

  coupon: {
    editCoupon: "Edit Coupon",
    errorMessageNoId: "No ID provided...",
    showCoupon: "Show Coupon",
    errorMessagePriceData: "getUserAutoCoupons - missing priceData",
    errorMessagePurchaseData: "getUserAutoCoupons - missing purchaseData"
  },

  userProdSubList: {
    subscriptions: "Subscriptions",
    status: "Status",
    platform: "Platform",
    subscription: "Subscription",
    downloadCSV: "Download CSV",
    product: "Product",
    cancelDate: "Cancel Date",
    showProduct: "Show Product",
    showCustomer: "Show Customer",
    refundLatestPay: "Refund Latest Payment",
    cancelFutureBookings: "Cancel Future Bookings Paid with Subscription",
    cancelSubscription: "Cancel Subscription",
  },


  countries: {
    copenhagen: "Europe/Copenhagen",
    helsinki: "Europe/Helsinki",
    berlin: "Europe/Berlin",
    oslo: "Europe/Oslo",
    stockholm: "Europe/Stockholm",
    errorMessageTimezone: "Missing this timezone"
  },

  stripeTs:{
    errorMessagePaymentToken:'initCardPayment - missing paymentToken',
    errorMessageClientSecret: 'initCardPayment - missing stripeClientSecret',
    errorMessagePaymentSheet: "Error with presentPaymentSheet -",
    errorMessageCustomerId: 'initCardPayment - missing stripeCustomerId',
    errorMessageEphemeralKey: 'initCardPayment - missing stripeEphemeralKey',
    errorGooglePayClientSecret: 'initGooglePay - missing stripeClientSecret',
    errorGooglePayIntentId:'initGooglePay - missing stripePaymentIntentId',
    errorGooglePayPaymentToken: 'initGooglePay - missing paymentToken',
    errorApplePayClientSecret: 'initApplePay - missing stripeClientSecret',
    errorApplePayIntentId:'initApplePay - missing stripePaymentIntentId',
    errorApplePayPaymentToken: 'initApplePay - missing paymentToken'
  },

  restPayment:{
    errorMessagePriceData: 'getCanDoRestPayment - missing priceData',
    errorMessageSelPayMethod: 'getCanDoRestPayment - no selectedPaymentMethod'
  },

  productReservation: {
    errorMessagePurchaseData: "cancelReservedProduct - missing purchaseData",
    errorMessageResProdData: "cancelReservedProduct - missing reservedProductData",
    errorMessagePriceData: 'reserveProduct - missing priceData'
  },

  paymentToken: {
    errorMessagePayToken:'createPaymentToken - could not set paymentTokenMethod',
    errorMessagePurchaseData: 'createPaymentToken - missing purchaseData',
    errorMessagePriceData: 'createPaymentToken - missing priceData',
    errorMessageAlreadyPayToken: 'createPaymentToken - paymentToken already is set',
    errorMessagePayTokenFailed: 'paymentTokenFailed - missing paymentToken',
    errorMessageConfirmPayToken: 'confimPaymentToken - missing paymentToken',
    errorMessageSubPayToken: 'subscribePaymentToken - missing paymentToken',
    errorMessageNotCreate: 'clearPaymentToken - can not create create, because we have paid paymentToken'
  },

  payMethod: {
    errorMessagePriceData: 'getAllowedPaymentTypes - missing priceData',
    errorMessagePayType: 'paymentType - trying to set payment type on one witch is now allowed'
  },

  pay: {
    errorMessageDoneFunction: 'paySuccess - missing doneFunction',
    aboutToPayCanceled: 'You were about to pay. The payment has now been canceled.',
    errorMessageDontKnow: 'startPayment - dont know what to do here',
    errorMessageFalseInitialized: 'startPayment - payPayStore.isInitialized is false',
    errorMessageNotReady: 'startPayment - not readyForPayment',
    errorMessagePayStarted: 'startPayment - paymentIsStarted is true',
    errorMessageNotReadyInit: 'initStartPayment - not readyForPayment',
    errorMessageUnableToReserveProduct: 'Unable to reserve product, please try again.',
    errorMessageUnableToReserveCourt: 'Unable to reserve court. Please try another court.'
  },

  auth: {
    errorMessageNotSignedIn: 'You have to send sign-in data then the user token is not defined',
    errorMessageAuthToken: 'Missing user auth token...',
    errorMessageConnectionId: 'Missing platform connection id',
    errorMessageFailure: 'An error occurred...'
  },

  workshop: {
    errorMessage: 'No workshop ID provided...',
    waitlist: "Waitlist",
  }, 
}