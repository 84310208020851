<template lang="pug">
b-modal(
  hide-header
  v-model="showUpdateAppModal"
  ref="add-user-to-group-modal"
  title-tag="h4"
  centered
  hide-footer
  size="md")
  h3 {{ $t('components.shared.appVersionModal.title') }}
  p.text-muted {{ $t('components.shared.appVersionModal.text') }}

  b-button(
    block
    @click="openAppInAppStore()"
    variant="primary") {{ $t('components.shared.appVersionModal.openAppStore') }}

  small.text-muted.mt-2 {{ appVersion }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {  } from '@/graphql'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'

@Component({
  components: {

  },
  apollo: {

  }
})
export default class CompanyUsersUserView extends Vue {

  created() {
    this.checkForRightVersion()
  }

  async openUrl(openUrl: string) {
    const win = window.open(openUrl, '_blank')
		win!.focus()
  }

	openAppInAppStore() {
		const platform = Capacitor.getPlatform()
		if (platform === 'android') this.openUrl('https://play.google.com/store/apps/details?id=dk.bookli.app')
		else if (platform === 'ios') this.openUrl('https://apps.apple.com/dk/app/bookli/id1551810263')
	}

  /*
  Target version is currently not in use.
  It was previously used for displaying a 
  modal for the user if a new version
  of the app was available. Since this
  is currently not in use, it is not
  crucial to update this when making new
  native app releases. Current target
  version is 2.6 for iOS and 2.5 for
  Android.
  */
	targetVersion = '2.4'
	showUpdateAppModal = false
	appVersion = ''
	async checkForRightVersion() {
		if (Capacitor.getPlatform() !== 'web') {
			const appInfo = await App.getInfo()
			if (appInfo) {
				this.appVersion = `v${appInfo.version} - b.: ${appInfo.build}`
				const currentVersion = appInfo.version

				if (currentVersion !== this.targetVersion) {
					// this.showUpdateAppModal = true
				}
			}
		}
	}
}
</script>

<style lang="sass" scoped>

</style>