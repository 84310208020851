import { defineStore } from 'pinia'
import { changeLocale, i18n } from '@/locales/setupI18n'

export enum LocaleType{
  DaDk = 'da-DK',
  DeDe = 'de-DE',
  EnGb = 'en-GB'
}

interface LocaleState {
  posibleLocales: LocaleType[]
  changingLocaleLoading: boolean
}

export const useLocaleStore = defineStore({
  id: 'App',

  state: (): LocaleState => ({
    changingLocaleLoading: false,
    posibleLocales: [
      LocaleType.DaDk,
      LocaleType.DeDe,
      LocaleType.EnGb
    ]
  }),

  getters: {

  },

  actions: {
    async changeLocale(setLocale: LocaleType) {
      this.changingLocaleLoading = true    
      await changeLocale(setLocale)
      this.changingLocaleLoading = false
    }
  }
})
